import Queue from '../datastructure/Queue'
import { IContext } from '../pam4-tracker'
import { PamEvent, PromisePamBulkEvent, PromisePamEvent } from '../PamEvent'
import { getRequester, writeCookie } from '../util'
import { ITrackerResponse, ICustomerConsentDetail, IConsentMessage } from './types'
import { getCurrentDBType, saveContactID } from '../util'
import { Task, TaskType } from '../datastructure/Queue'

export default class Service {
  constructor(private ctx: IContext, private eventQueue: Queue<Task>) {}

  public async sendBulkEvent(data: Record<string, any>): Promise<ITrackerResponse[]> {
    let globalResolve!: (res: ITrackerResponse[]) => void
    let globalReject!: (reason: any) => void

    const promise = new Promise<ITrackerResponse[]>((resolve, reject) => {
      globalResolve = resolve
      globalReject = reject
    })

    const event = new PromisePamBulkEvent(data, globalResolve, globalReject)

    const task = new Task(TaskType.TrackerBulk, event)

    this.eventQueue.enqueue(task)

    try {
      const data = await promise
      writeCookie('contact_id', data[0].contact_id, 365 * 5)
      saveContactID(data[0].contact_id, getCurrentDBType())

      return data
    } catch (e) {
      return e as any
    }
  }

  public async sendEvent(
    eventName: string,
    tags = '',
    formFields = {},
    specificDatabase = 'AUTO'
  ): Promise<ITrackerResponse> {
    let globalResolve!: (res: ITrackerResponse) => void
    let globalReject!: (reason: any) => void

    const promise = new Promise<ITrackerResponse>((resolve, reject) => {
      globalResolve = resolve
      globalReject = reject
    })

    const event = new PromisePamEvent(
      eventName,
      formFields,
      globalResolve,
      globalReject,
      tags.split(','),
      specificDatabase
    )

    const task = new Task(TaskType.TrackerSingle, event)

    this.eventQueue.enqueue(task)

    try {
      const data = await promise
      writeCookie('contact_id', data.contact_id, 365 * 5)
      saveContactID(data.contact_id, getCurrentDBType())

      return data
    } catch (e) {
      return e as any
    }
  }

  public fetchConsentMessage(consentMessageId = this.ctx.config.trackingConsentMessageId): Promise<IConsentMessage> {
    const url = `${this.ctx.config.baseApi}/consent-message/${consentMessageId}`
    return getRequester(url)
  }

  public getCustomerConsentDetail(contactId: string, consentMsgId: string): Promise<ICustomerConsentDetail> {
    return getRequester(`${this.ctx.config.baseApi}/contacts/${contactId}/consents/${consentMsgId}`)
  }
}
