export enum State {
  PUSHER_STATE_SHOW = 'pusher_state_show',
  PUSHER_STATE_HIDE = 'pusher_state_hide',
}

export enum NotificationPermission {
  DEFAULT = 'default',
  GRANTED = 'granted',
  DENIED = 'denied',
}

export interface IPopupConfig {
  autoSubscribe?: boolean
  enableWebPushPopup?: boolean
  enableWebPushNavigation?: boolean
  title?: string
  message?: string
  button_text?: string
  navigation_text?: string
  primary?: string
  secondary?: string
  primary_font?: string
  secondary_font?: string
  animate_type_btn?: string
}
