import { ITrackerResponse } from './Service/types'

export class PamEvent {}

export class PromisePamEvent extends PamEvent {
  constructor(
    public name: string,
    public formFields: Record<string, unknown>,
    public resolve: (res: ITrackerResponse) => void,
    public reject: (reason?: any) => void,
    public tags?: string[],
    public specificDatabase: string = 'AUTO'
  ) {
    super()
  }
}

export class PromisePamBulkEvent extends PamEvent {
  constructor(
    public data: Record<string, any>,
    public resolve: (res: ITrackerResponse[]) => void,
    public reject: (reason?: any) => void
  ) {
    super()
  }
}
