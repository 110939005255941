export const PUSHER_POPUP_OPEN_CLASS = 'pam-pusher-popup-open'
export const PUSHER_STATE_KEY = 'pusher_state_key'

export const ERROR = {
  USER_IS_SUBSCRIBED: 'User is subscribed',
  SUBSCRIBED_FAIL: 'failed to subscribe the user',
  NOT_SUPPORTED: 'Push messaging is not supported',
  SERVICE_WORKER_ERROR: 'Service Worker Error',
  SUBSCRIPTION_SERVER_ERROR: 'Cannot send subscription data to server',
  SUBSCRIPTION_ERROR: 'Cannot subscribe',
}

export const POPUP_CONFIG = {
  TITLE: 'Connect with us',
  MESSAGE: `We'd like to show you
  notifications for the
  latest news and updates`,
  BUTTON_TEXT: 'Subscribe',
  NAVIGATION_TEXT: 'Click allow to subscribe',
  PRIMARY: '#3d3d3d',
  SECONDARY: '#ffffff',
  ANIMATE_TYPE_BTN: 'vertical', // horizontal
}

export const EVENT = {
  SHOW: 'show',
  HIDE: 'hide',
  SUBSCRIBE: 'subscribe',
}
