import { IContext } from '../pam4-tracker'
import { PusherPopup } from './PusherPopup'
import ShadowDOM from '../ShadowDOM'
import { IPopupConfig, NotificationPermission, State } from './types'
import { ERROR, PUSHER_STATE_KEY } from './consts'
import { getRequester, postRequester, readCookie, urlB64ToUint8Array, windowOrigin } from '../util'
import PusherNavigation from './PusherNavigation'
import urlJoin from 'url-join'

export default class Pusher extends ShadowDOM {
  private subscription: PushSubscription | null = null
  private swRegistration: ServiceWorkerRegistration | null = null
  private content!: PusherPopup
  public isSubscribed = false
  public permission: string
  private ctx!: IContext
  public pusherNavigation!: PusherNavigation

  constructor() {
    super()
    this.permission = Notification.permission
  }

  public async init(ctx: IContext): Promise<IPopupConfig> {
    this.ctx = ctx
    try {
      await this._registerServiceWorker()
      const config = await this._fetchConfig()
      if (config.enableWebPushNavigation) {
        this.pusherNavigation = config.navigation_text
          ? new PusherNavigation(config.navigation_text)
          : new PusherNavigation()
      }
      return config
    } catch (err) {
      throw err
    }
  }

  public subscribe(): void {
    this.close()
    if (this.permission === NotificationPermission.DEFAULT) {
      if (this.pusherNavigation) {
        this.showNavigation()
      }
      Notification.requestPermission().then(() => {
        if (this.pusherNavigation) {
          this.hideNavigation()
        }
        const applicationServerKey = urlB64ToUint8Array(this.ctx.config.webPushPublicKey)
        if (this.swRegistration) {
          this.swRegistration.pushManager
            .subscribe({
              userVisibleOnly: true,
              applicationServerKey: applicationServerKey,
            })
            .then((subscription: PushSubscription) => {
              this.subscription = subscription
              this._updateSubscriptionOnServer(subscription, this.ctx.config.pusherAlias).catch((e) => {
                window.console.error(ERROR.SUBSCRIPTION_SERVER_ERROR, e)
              })
            })
            .catch((e) => {
              window.console.error(ERROR.SUBSCRIPTION_ERROR, e)
            })
        }
      })
    } else {
      this.swRegistration &&
        this.swRegistration.pushManager
          .getSubscription()
          .then((subscription) => {
            if (subscription) {
              this._updateSubscriptionOnServer(subscription, this.ctx.config.pusherAlias).catch((e) => {
                window.console.error(ERROR.SUBSCRIPTION_SERVER_ERROR, e)
              })
              this.subscription = subscription
            } else {
              window.console.error(ERROR.SUBSCRIPTION_ERROR, subscription)
            }
          })
          .catch((e) => {
            window.console.error(ERROR.SUBSCRIPTION_ERROR, e)
          })
    }
  }

  public render(config: IPopupConfig = {}): void {
    super.render()
    this.content = new PusherPopup(
      this.shadowRoot,
      this.shadowHost,
      config,
      () => this.subscribe(),
      () => this.show(),
      () => this.hide()
    )
    if (!this.isShown()) {
      this.hide()
    }
  }

  public close(): void {
    super.close()
  }

  public show(): void {
    this.shadowRoot.querySelector('.popup-container')!.classList.add('bell-alert')
    this.shadowRoot.querySelector('#panel')!.classList.remove('active-btn-form')
    this.shadowRoot.querySelector('#panel')!.classList.add('active-btn-to')
    this.shadowRoot.querySelector('#wrapper-noti-body')!.classList.add('active-body-desc')
    this.setState(PUSHER_STATE_KEY, State.PUSHER_STATE_SHOW)
  }

  public hide(): void {
    this.shadowRoot.querySelector('.popup-container')!.classList.remove('bell-alert')
    this.shadowRoot.querySelector('#panel')!.classList.remove('active-btn-to')
    this.shadowRoot.querySelector('#panel')!.classList.add('active-btn-from')
    this.shadowRoot.querySelector('#wrapper-noti-body')!.classList.remove('active-body-desc')
    this.setState(PUSHER_STATE_KEY, State.PUSHER_STATE_HIDE)
  }

  public showNavigation(): void {
    if (this.pusherNavigation) {
      this.pusherNavigation.render()
    }
  }

  public hideNavigation(): void {
    if (this.pusherNavigation) {
      this.pusherNavigation.close()
    }
  }

  public isShown(): boolean {
    const state = this.getState(PUSHER_STATE_KEY)
    return !state || state === State.PUSHER_STATE_SHOW
  }

  private setState(key: string, state: State): void {
    window.localStorage.setItem(key, state)
  }

  private getState(key: string): State | null {
    const state = window.localStorage.getItem(key)
    if (!state) {
      return null
    }
    return window.localStorage.getItem(key)! as State
  }

  private _registerServiceWorker(): Promise<null> {
    return new Promise((resolve, reject) => {
      if ('serviceWorker' in navigator && 'PushManager' in window) {
        navigator.serviceWorker
          .register(urlJoin(windowOrigin(), this.ctx.config.prefixPathWorkerScript, 'pam-sw.js'))
          .then((swReg: ServiceWorkerRegistration) => {
            this.swRegistration = swReg
            return this._notify()
          })
          .then((res) => resolve(res))
          .catch((error) => {
            reject(`${ERROR.SERVICE_WORKER_ERROR}: ${error}`)
          })
      } else {
        reject(ERROR.NOT_SUPPORTED)
      }
    })
  }

  private _notify(): Promise<null> {
    return new Promise((resolve, reject) => {
      this.swRegistration &&
        this.swRegistration.pushManager.getSubscription().then((subscription: PushSubscription | null) => {
          this.subscription = subscription
          this.isSubscribed = !(subscription === null)

          if (this.isSubscribed) {
            reject(ERROR.USER_IS_SUBSCRIBED)
          } else {
            resolve(null)
          }
        })
    })
  }

  private _updateSubscriptionOnServer(subscription: PushSubscription, alias: string): Promise<PushSubscriptionJSON> {
    const url = `${this.ctx.config.baseApi}/api/pub/contacts/${readCookie('contact_id')}/webpush/${alias}`
    return postRequester(url, subscription.toJSON())
  }

  private _fetchConfig(): Promise<IPopupConfig> {
    const url = `${this.ctx.config.baseApi}/api/media/webpush/${this.ctx.config.pusherAlias}/config`
    return getRequester(url)
  }
}

export * from './consts'
export * from './types'
