import { toggleClass } from '../util'
import { PUSHER_POPUP_OPEN_CLASS, EVENT } from './consts'
import { popupTemplate } from './popupTemplate'
import { IPopupConfig } from './types'
import ShadowDOM from '../ShadowDOM'

export class PusherPopup {
  constructor(
    private shadowRoot: ShadowRoot,
    private shadowHost: HTMLDivElement,
    public config: IPopupConfig = {},
    public onSubscribe: VoidFunction,
    public onShow: VoidFunction,
    public onHide: VoidFunction
  ) {
    this._render()
  }

  public close(): void {
    if (this.shadowHost) {
      toggleClass(window.document.body, PUSHER_POPUP_OPEN_CLASS)
      this.shadowHost.remove()
    }
  }

  private _render(): void {
    const section = window.document.createElement('section')
    this._createElement(section)
    toggleClass(window.document.body, PUSHER_POPUP_OPEN_CLASS)
  }

  private _createElement(section: HTMLElement): void {
    section.innerHTML = popupTemplate(this.config)
    this.registerEventListeners(section)
    this.addEventDispatchers(section)
    this.shadowRoot.appendChild(section)
  }

  private registerEventListeners(section: HTMLElement): void {
    section.addEventListener(EVENT.SHOW, this.onShow)
    section.addEventListener(EVENT.HIDE, this.onHide)
    section.addEventListener(EVENT.SUBSCRIBE, this.onSubscribe)
  }

  private addEventDispatchers(section: HTMLElement): void {
    ;(section.querySelector('#panel')! as HTMLElement).onclick = () =>
      section.dispatchEvent(ShadowDOM.bubblesEvent(EVENT.SHOW))
    ;(section.querySelector('#showButton')! as HTMLElement).onclick = () =>
      section.dispatchEvent(ShadowDOM.bubblesEvent(EVENT.SHOW))
    ;(section.querySelector('#closeButton')! as HTMLElement).onclick = () =>
      section.dispatchEvent(ShadowDOM.bubblesEvent(EVENT.HIDE))
    section.querySelector('button')!.onclick = () => section.dispatchEvent(ShadowDOM.bubblesEvent(EVENT.SUBSCRIBE))
  }
}
