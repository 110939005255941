export default class ShadowDOM {
  public shadowRoot!: ShadowRoot
  public shadowHost!: HTMLDivElement
  public isRendered: boolean

  constructor() {
    this.isRendered = false
  }

  public render(): void {
    if (this.isRendered) {
      return
    }
    this.shadowHost = window.document.createElement('div')
    const body = window.document.querySelector('body')!
    body.insertBefore(this.shadowHost, body.childNodes[0] || null)
    this.shadowRoot = this.shadowHost.attachShadow({ mode: 'open' })
    this.isRendered = true
  }

  public renderOnElement(elementId: string): void {
    this.shadowHost = window.document.createElement('div')
    const elm = <HTMLIFrameElement>window.document.getElementById(elementId)
    if (!elm) {
      throw new Error('element id is not exists')
    }
    const body = elm.contentDocument!.getElementsByTagName('body')[0]
    body.appendChild(this.shadowHost)
    this.shadowRoot = this.shadowHost.attachShadow({ mode: 'open' })
    this.isRendered = true
  }

  public setHTMLString(html: string): void {
    if (!this.isRendered) {
      return
    }
    const section = document.createElement('section')
    section.innerHTML = html
    this.shadowRoot.appendChild(section)
  }

  public setDOM(dom: HTMLElement): HTMLElement | null {
    const section = document.createElement('section')
    section.appendChild(dom)
    this.shadowRoot.appendChild(section)
    return section
  }

  public close(): void {
    if (this.isRendered) {
      this.shadowHost.remove()
      this.isRendered = false
    }
  }

  public static bubblesEvent(name: string): CustomEvent {
    return new CustomEvent(name, { bubbles: true })
  }
}
