import { IContext } from '../pam4-tracker'
import Popup, { IPopup } from './Popup'
import Injection from './Injection'
import { getFormatData, getPageURL, getScrollPercent, postRequester, serializeJSON, readCookie } from '../util'
import { AttentionType, DisplayTimeType, IAttentionItem } from './types'
import { ITrackerResponse } from '../Tracker'

export default class Attention {
  private shadow!: ShadowRoot
  private content!: IPopup
  private shadowHost!: HTMLDivElement
  private attention!: IAttentionItem
  private isRendered = false
  private intervalInstance!: NodeJS.Timeout
  private totalSeconds = 0
  private readonly firstFetchIntervalPeriod = 5
  private readonly fetchIntervalPeriod = 30 /* fetch every n seconds */

  constructor(private ctx: IContext) {
    this._fetchAndCount = this._fetchAndCount.bind(this)
  }

  public render(attention: IAttentionItem): void {
    if (!this.isRendered) {
      this.attention = attention

      if (attention.options.display_after.type === DisplayTimeType.PAGE_SCROLLING) {
        window.addEventListener('scroll', this._onScroll, true)
        return
      }

      let msCalc = 0
      if (attention.options.display_after.type === DisplayTimeType.TIME_SPEND) {
        const sec = attention.options.display_after.second
        if (sec) {
          const remainSec = Math.max(sec - this.totalSeconds, 0)
          msCalc = remainSec * 1000
        }
        this.isRendered = true
      }

      setTimeout(() => {
        this._show()
      }, msCalc)
    }
  }

  private _createEvent(eventName: string, detail: any): CustomEvent {
    return new CustomEvent(eventName, {
      bubbles: true,
      composed: true,
      detail,
    })
  }

  public async submit(form: HTMLFormElement, id: string): Promise<void> {
    const button = form.querySelector('button[type="submit"]')! as HTMLButtonElement
    let tags = ''
    if (button) {
      tags = button.getAttribute('data-tags') || ''
    }

    const formFields = {
      ...serializeJSON(form),
      _campaign: id,
      tags,
    }

    const data = getFormatData('form_submit', formFields)
    try {
      button.dispatchEvent(
        this._createEvent('pam-submit:request', {
          id: button.id,
          data,
        })
      )

      const res = await postRequester<ITrackerResponse>(`${this.ctx.config.baseApi}/trackers/events`, data)

      button.dispatchEvent(
        this._createEvent('pam-submit:success', {
          id: button.id,
          data,
          response: res,
        })
      )
    } catch (err) {
      button.dispatchEvent(
        this._createEvent('pam-submit:error', {
          id: button.id,
          data,
          error: err,
        })
      )
    } finally {
      this.close()
      const url = button.getAttribute('data-href')
      if (url) {
        window.location.href = url
      }
    }
  }

  public async tag(button: HTMLButtonElement, id: string): Promise<void> {
    const tags = button.getAttribute('data-tags') || ''
    const formFields = {
      _campaign: id,
      tags,
    }

    const data = getFormatData('attention_tag', formFields)
    try {
      button.dispatchEvent(
        this._createEvent('pam-tag:request', {
          id: button.id,
          data,
        })
      )

      if (!tags) {
        throw new Error('tags is empty')
      }

      const res = await postRequester<ITrackerResponse>(`${this.ctx.config.baseApi}/trackers/events`, data)

      button.dispatchEvent(
        this._createEvent('pam-tag:success', {
          id: button.id,
          data,
          response: res,
        })
      )
    } catch (err) {
      button.dispatchEvent(
        this._createEvent('pam-tag:error', {
          id: button.id,
          data,
          error: err,
        })
      )
    } finally {
      const url = button.getAttribute('data-href')
      if (url) {
        window.location.href = url
      }
    }
  }

  private _onScroll = () => {
    const percent = this.attention.options.display_after.percent || 0
    if (getScrollPercent() >= percent && !this.isRendered) {
      this._show()
    }
  }

  public close(): void {
    if (this.attention.options.type === AttentionType.POPUP) {
      window.removeEventListener('scroll', this._onScroll, true)
      this.content.close()
      window.dispatchEvent(
        new Event('pam-wa:close', {
          bubbles: true,
          composed: true,
        })
      )
    }
  }

  public async startFetchInterval(): Promise<void> {
    // try to fetch one time. if it found the web attention, skip the interval
    const attention = await this.fetch()
    if (Object.keys(attention).length) {
      this.render(attention)
      return
    }
    // delay first fetch interval, then start the normal interval
    setTimeout(() => {
      this._fetchAndCount(this.firstFetchIntervalPeriod)
      this.intervalInstance = setInterval(() => {
        this._fetchAndCount()
      }, this.fetchIntervalPeriod * 1000)
    }, this.firstFetchIntervalPeriod * 1000)
  }

  public stopFetchInterval(): void {
    if (this.intervalInstance) {
      clearInterval(this.intervalInstance)
    }
  }

  private async _fetchAndCount(second = this.fetchIntervalPeriod): Promise<void> {
    try {
      const attention = await this.fetch()
      if (Object.keys(attention).length) {
        this.render(attention)
        this.stopFetchInterval()
      }
      this.totalSeconds += second
    } catch (e) {
      console.error(e)
    }
  }

  public fetch(): Promise<IAttentionItem> {
    const contactId = readCookie(`contact_id`) ?? ''
    return postRequester<IAttentionItem>(this.ctx.config.baseApi + '/attention', {
      page_url: decodeURI(getPageURL()),
      _contact_id: contactId,
    })
  }

  private _show = () => {
    if (this.attention.options.type === AttentionType.POPUP) {
      this.shadowHost = window.document.createElement('div')
      this.shadowHost.id = `pam_attention`

      const body = window.document.querySelector('body')!
      body.insertBefore(this.shadowHost, body.childNodes[0] || null)

      this.shadow = this.shadowHost.attachShadow({ mode: 'open' })

      const style = window.document.createElement('style')
      let css = this.attention.css
      if (this.attention.custom_css) {
        css += this.attention.custom_css.replace('\n', '')
      }
      style.innerHTML = css
      this.shadow.appendChild(style)

      this.content = new Popup(this.ctx, this.shadow, this.shadowHost, this.attention, () => this.close())

      if (this.attention.js) {
        const script = window.document.createElement('script')
        const inlineScript = window.document.createTextNode(this.attention.js)
        script.appendChild(inlineScript)
        body.appendChild(script)
      }

      window.dispatchEvent(
        new Event('pam-wa:open', {
          bubbles: true,
          composed: true,
        })
      )
    } else if (this.attention.options.type === AttentionType.REPLACE || AttentionType.PREPEND || AttentionType.APPEND) {
      this.content = new Injection(this.ctx, this.attention)
    } else {
      return
    }

    if (!this.isRendered) {
      this.isRendered = true
    }
  }
}
