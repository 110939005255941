import ShadowDOM from '../ShadowDOM'
import { IConsentMessage, IPermissionBody, ITrackerResponse } from '../Service/types'

export default class NotificationPopup extends ShadowDOM {
  constructor(
    private consentDetail: IConsentMessage,
    private submitConsentPermission?: (updatedConsentAcceptation: IPermissionBody) => Promise<ITrackerResponse>,
    private immediatelySubmit: boolean = false,
    private callback?: (prev: any, state: any) => void,
    private previewMode: boolean = false,
    private disableToggleOptions: boolean = false
  ) {
    super()
  }

  trackingConsentDetailType: any = {
    terms_and_conditions: {
      label: 'Terms & Conditions',
      value: 'terms_and_conditions',
      isAlwaysAccepted: true,
    },
    privacy_overview: {
      label: 'Privacy Overview',
      value: 'privacy_overview',
      isAlwaysAccepted: true,
    },
    necessary_cookies: {
      label: 'Necessary Cookies',
      value: 'necessary_cookies',
      isAlwaysAccepted: true,
    },
    preferences_cookies: {
      label: 'Preferences Cookies',
      value: 'preferences_cookies',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
    analytics_cookies: {
      label: 'Analytics Cookies',
      value: 'analytics_cookies',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
    marketing_cookies: {
      label: 'Marketing Cookies',
      value: 'marketing_cookies',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
    social_media_cookies: {
      label: 'Social Media Cookies',
      value: 'social_media_cookies',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
  }

  contactingTypeDetailOptions: any = {
    terms_and_conditions: {
      label: 'Terms & Conditions',
      value: 'terms_and_conditions',
      isAlwaysAccepted: true,
    },
    privacy_overview: {
      label: 'Privacy Overview',
      value: 'privacy_overview',
      isAlwaysAccepted: true,
    },
    email: {
      label: 'Email',
      value: 'email',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
    sms: {
      label: 'SMS',
      value: 'sms',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
    line: {
      label: 'LINE',
      value: 'line',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
    facebook_messenger: {
      label: 'Facebook Messenger',
      value: 'facebook_messenger',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
    push_notification: {
      label: 'Push Notification',
      value: 'push_notification',
      isAlwaysAccepted: this.disableToggleOptions || false,
    },
  }

  consentDetailType =
    this.consentDetail.consent_message_type === 'tracking_type'
      ? this.trackingConsentDetailType
      : this.contactingTypeDetailOptions
  activeLanguage = this.consentDetail.setting.default_language.toLowerCase()
  selectedConsentDetailTypeIdx = 0

  updatedConsentAcceptation: IPermissionBody = {
    consent_message_id: this.consentDetail.consent_message_id,
    version: this.consentDetail.setting.version!,
    permission: {},
  }

  private template = `
    <div class="pam-root">
        <style>
            .pam-root {
              font-family: system-ui, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
            }
            * {
                box-sizing: border-box;
            }
            
            body {
                margin: 0;
                background-color: indigo;
            }
            
            #consent-noti {
                position: fixed;
                display: flex;
                justify-content: center;
                bottom: 0;
                left: 0;
                width: 100%;
                z-index: 900001;
                /* background color and opacity */
                background-color: ${this.consentDetail.style_configuration.bar_background_color};
                opacity: ${this.consentDetail.style_configuration.bar_background_opacity_percentage / 100};
            }
            
            .container {
                display: flex;
                align-content: center;
                width: 100%;
            }
            
            .container>div {
                display: flex;
                align-items: center;
            }
            
            #consent-txt-container {
                flex-grow: 1;
                padding: 20px 20px 20px 40px;
            }
            
            #consent-txt {
                color: ${this.consentDetail.style_configuration.bar_text_color};
                font-size: 11pt;
            }
            
            #consent-txt a:link,
            #consent-txt a:visited,
            #consent-txt a:active {
                color: ${this.consentDetail.style_configuration.bar_text_color};
            }
            
            #consent-txt a:hover {
                color: ${this.consentDetail.style_configuration.button_background_color};
            }
            
            #consent-noti-btn-container {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: space-between;
                padding: 15px;
            }
            
            #language-btn-container {
                color: ${this.consentDetail.style_configuration.bar_text_color};
                font-size: 10pt;
                margin-bottom: 10px;
            }
            
            #language-btn-container .language-btn {
                cursor: pointer;
            }
            
            #language-btn-container .active-language-btn {
                color: ${this.consentDetail.style_configuration.consent_detail.primary_color} !important;
            }

            #language-btn-container .select-language {
              cursor: pointer;
              border: none;
              background: none;
              color: white;
              border-color: white;
          }
            
            .accept-btn {
                cursor: pointer;
                /* btn color */
                background-color: ${this.consentDetail.style_configuration.button_background_color};
                /* btn text color */
                color: ${this.consentDetail.style_configuration.button_text_color};
                padding: 12px 48px;
                border-radius: 6px;
                font-size: 12pt;
            }
            
            .accept-btn:hover {
                opacity: 0.8;
            }
            
            .more-info-link {
                margin-left: 6px;
                text-decoration: underline;
                cursor: pointer;
            }
            
            .more-info-link:hover {
                color: ${this.consentDetail.style_configuration.button_background_color};
            }
            
            .notification-close-btn {
                position: absolute;
                top: 8px;
                left: 8px;
                height: 12px;
                width: 12px;
            }
            
            .close-btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                cursor: pointer;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `768px`}) {
                .close-btn {
                    justify-content: flex-start;
                }
            }
            
            .close-btn:hover {
                opacity: 0.5;
            }
            
            .notification-close-btn::before,
            .notification-close-btn::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                /* btn color */
                background-color: ${this.consentDetail.style_configuration.button_background_color};
            }
            
            .close-btn::before {
              
            }
            
            .close-btn::after {
               
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `768px`}) {
                .container {
                    flex-direction: column;
                }
                #consent-txt-container {
                    padding: 30px 15px 5px;
                }
                .accept-btn {
                    text-align: center;
                    width: 100%;
                }
            }
            /* modal */
            
            .overlay {
                position: fixed;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: rgba(0, 0, 0, 0.5);
                z-index: 900002;
            }
            
            .consent-message-modal {
                padding: 10px;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: ${this.previewMode ? `calc((100vh / 3) * 4)` : `800px`};
                max-width: ${this.previewMode ? `95%` : `80%`};
                height: ${this.previewMode ? `95%` : `90% !important`};
                max-height: 550px;
                min-height: 280px;
                background-color: #ffffff;
                z-index: 900003;
                border-radius: 7px;
                font-family: "Roboto", sans-serif;
                display: flex;
                flex-direction: column;
            }
            
            .consent-message-topic-container {
                display: flex;
                margin-bottom: 5px;
            }
            
            .consent-message-topic {
                display: flex;
                align-items: center;
            }
            
            .pop-up-main-icon-container {
                display: flex;
                align-items: center;
                margin-right: 15px;
                color: ${this.consentDetail.style_configuration.consent_detail.primary_color};
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `768px`}) {
                .consent-message-modal {
                    padding: 10px;
                }
                .consent-message-topic {
                    flex-direction: column;
                    align-items: flex-start;
                    flex-basis: 100%;
                    margin-top: 0;
                }
                .pop-up-main-icon-container {
                    margin-bottom: 10px;
                    pop-up-main-icon-container
                }
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`}) {
                .consent-message-modal {
                    height: ${this.previewMode ? `95%` : `70%`};
                }
            }
            
            .pop-up-main-icon {
                margin-right: 10px;
                width:30px;
                height:30px
            }
            
            .consent-message-topic-container h2,
            h3 {
                margin: 0;
            }
            
            .active-modal-language-btn {
                box-shadow: 0px 0px 0px 1px ${
                  this.consentDetail.style_configuration.consent_detail.primary_color
                } inset;
            }
            
            .inactive-language-btn {
                background-color: #EBEBEB;
            }
            
            .consent-message-container {
                display: flex;
                height: calc(80% - 55px);
                flex-grow: 1;
                border: 1px solid #C6C6C6;
                flex-basis: 80%;
                z-index: 2;
                margin-bottom: auto;
            }
            
            #consent-message-container {
                border-radius: 8px;
            }
            
            .consent-detail-title {
                color: #303131;
            }
            
            #consent-detail-type-tab-bar {
                display: flex;
                flex-direction: column;
                flex-basis: 30%;
                border-right: 1px solid #C6C6C6;
                background-color: #EBEBEB;
                border-radius: 8px 0px 0px 8px;
                overflow-y: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
            
            #consent-detail-type-tab-bar::-webkit-scrollbar {
                display: none;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `768px`}) {
                #consent-message-container {
                    display: none;
                }
            }
            
            .consent-detail-type-tab {
                display: flex;
                align-items: center;
                padding: 15px;
                cursor: pointer;
                font-size: 11pt;
            }
            
            .consent-detail-type-tab:first-of-type {
                border-radius: 8px 0 0 0;
            }
            
            .consent-detail-type {
                color: #303131;
                font-weight: 500;
            }
            
            .active-consent-type-icon-container {
                flex-basis: 24px;
                margin-right: 10px;
                height: 24px;
            }
            
            .active-consent-type-icon-container svg {
                width: 24px;
                height: 24px;
                color: #71D879;
            }
            
            .modal-lang-and-close-btn {
                margin-left: auto;
                display: flex;
            }
            
            #modal-language-btn-container {
                display: flex;
                align-items: center;
                margin-right: 15px;
            }

            #modal-language-btn-container .select-language {
              border: none;
              background: #EBEBEB;
              cursor: pointer;
              padding: 5px;
              border-radius: 4px;
            }

            .select-language:focus-visible {
              outline: none;
            }
            
            .modal-language-btn {
                padding: 10px;
                color: ${this.consentDetail.style_configuration.consent_detail.primary_color};
                font-size: 10pt;
                height: 35px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
            }
            
            .modal-language-btn:first-of-type {
                border-radius: 8px 0px 0px 8px;
            }
            
            .modal-language-btn:last-of-type {
                border-radius: 0px 8px 8px 0px;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`}) {
                .modal-lang-and-close-btn {
                    top: 15px;
                    right: 10px;
                }
                #modal-language-btn-container {
                    margin-right: 5px;
                }
                .modal-language-btn {
                    height: 25px;
                    width: 30px;
                    font-size: 10px;
                }
                .modal-language-btn:first-of-type {
                    border-radius: 5px 0px 0px 5px;
                }
                .modal-language-btn:last-of-type {
                    border-radius: 0px 5px 5px 0px;
                }
            }
            
            .modal-close-btn::after {
                content: '\\00d7';
                color: #9CACAC;
                font-size:30px;
                margin-top: -15px;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`}) {
                .modal-close-btn::before,
                .modal-close-btn::after {
                    width: 70%;
                }
            }
            
            .modal-tab:hover {
                background-color: #f1f1f1;
            }
            
            .modal-tab-active {
                background-color: #FFFFFF;
            }
            
            .consent-message-content-container {
                flex-basis: 75%;
                display: flex;
                flex-direction: column;
                height: 100%;
                padding: 15px 25px;
            }
            
            .modal-content-topic-container {
                margin-bottom: 15px;
                max-height: 20%;
                color: ${this.consentDetail.style_configuration.consent_detail.secondary_color};
            }
            
            #full-consent-message-container .modal-content-topic-container {
                margin: 0px 0px 15px !important;
            }
            
            #full-consent-message-container .consent-message-content-container {
                flex-basis: 100%;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `768px`}) {
                #full-consent-message-container .consent-message-content-container {
                    padding: 15px;
                }
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`}) {
                #full-consent-message-container .consent-message-content-container {
                    padding: 10px;
                }
                #modal-full-content-topic {
                    font-size: 15px !important;
                }
            }
            
            .consent-message-description-container {
                flex-basis: 80%;
                overflow-y: scroll;
                margin-bottom: auto;
                max-height: 100%;
            }
            
            #full-consent-message-container .consent-message-description-container {
                flex-basis: 100%;
            }
            
            .consent-message-description-container p {
                font-size: 11pt;
                color: ${this.consentDetail.style_configuration.consent_detail.text_color};
                margin: 0;
            }
            
            #modal-content-topic,
            #modal-full-content-topic,
            .mobile-modal-content-topic {
                margin: 0;
                color: #5A5F5F;
                font-size: 14pt;
                font-weight: 500;
            }
            
            .modal-content p {
                font-size: 11pt;
                font-weight: 500;
                color: #303131;
            }
            
            #modal-content-footer-container,
            .mobile-modal-content-footer-container {
                padding-top: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            
            .mobile-modal-content-footer-container {
                margin: 0 10px;
            }
            
            #full-version-btn-container .modal-content-footer-text,
            .mobile-full-version-btn-container>.modal-content-footer-text {
                text-decoration: underline;
                cursor: pointer;
            }
            
            .modal-content-footer-text {
                font-size: 10pt;
                font-weight: 500;
                color: ${this.consentDetail.style_configuration.consent_detail.text_color};
            }
            
            #accept-by-type-toggle-container {
                display: flex;
                align-items: center;
            }
            
            #consent-message-modal-footer-container {
                margin-top: 20px;
            }
            
            #consent-message-modal-footer-container button {
                color: ${this.consentDetail.style_configuration.consent_detail.button_text_color};
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`}) {
                #consent-message-modal-footer-container {
                    margin-top: 10px;
                }
            }
            
            #brief-description-consent-message-btn-container {
                display: flex;
                justify-content: flex-end;
            }
            
            #full-description-consent-message-btn-container {
                display: flex;
                justify-content: space-between;
            }
            
            .consent-message-save-button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${this.consentDetail.style_configuration.consent_detail.primary_color};
                margin-right: 8px;
                width: 150px;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`}) {
                #full-description-consent-message-btn-container .consent-message-save-button {
                    display: none !important;
                }
            }
            
            .consent-message-accept-button {
                background-color: ${this.consentDetail.style_configuration.consent_detail.secondary_color};
                width: 150px;
            }
            
            .consent-message-back-button {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: ${this.consentDetail.style_configuration.consent_detail.secondary_color};
            }
            
            .consent-message-btn-container button {
                border: none;
                border-radius: 7px;
                padding: 10px 20px;
                color: ${this.consentDetail.style_configuration.consent_detail.button_text_color};
                cursor: pointer;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`}) {
                #brief-description-consent-message-btn-container {
                    width: 70%;
                    margin-left: auto;
                }
                #brief-description-consent-message-btn-container button {
                    padding: 10px 5px;
                    font-size: 10px;
                }
                #full-description-consent-message-btn-container button {
                    padding: 10px;
                }
            }
            
            .btn-icon-img {
                width: auto;
                height: 15px;
                filter: invert(100%);
                -webkit-filter: invert(100%);
                margin-right: 8px;
            }
            
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 7px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 4px;
                background-color: rgba(0, 0, 0, .5);
                box-shadow: 0 0 1px rgba(255, 255, 255, .5);
            }
            /* The switch - the box around the slider */
            
            .accept-by-type-switch {
                position: relative;
                display: inline-block;
                width: 30px;
                height: 17px;
                margin-right: 7px;
            }
            /* Hide default HTML checkbox */
            
            .accept-by-type-switch input {
                opacity: 0;
                width: 0;
                height: 0;
            }
            /* The slider */
            
            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #EBEBEB;
                -webkit-transition: .4s;
                transition: .4s;
            }
            
            .slider:before {
                position: absolute;
                content: "";
                height: 13px;
                width: 13px;
                left: 2px;
                bottom: 2px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
            }
            
            input:checked+.slider {
                background-color: #71D879;
            }
            
            input:focus+.slider {
                box-shadow: 0 0 1px #71D879;
            }
            
            input:checked+.slider:before {
                -webkit-transform: translateX(13px);
                -ms-transform: translateX(13px);
                transform: translateX(13px);
            }
            /* Rounded sliders */
            
            .slider.round {
                border-radius: 34px;
            }
            
            .slider.round:before {
                border-radius: 50%;
            }
            
            .hide-element {
                display: none !important;
            }
            /* Mobile Reponsive Consent Message */
            
            #mobile-consent-message-container {
                display: flex;
                flex-direction: column;
                height: 80%;
                border: 1px solid #C6C6C6;
                flex-basis: 80%;
                z-index: 2;
                margin-bottom: auto;
                border-radius: 8px 8px 0 0;
                overflow-y: scroll;
                -ms-overflow-style: none;
                scrollbar-width: none;
            }
            
            #mobile-consent-message-container::-webkit-scrollbar {
                display: none;
            }
            
            @media only screen and (min-width: ${this.previewMode ? `376px` : `769px`}) {
                #mobile-consent-message-container {
                    display: none;
                }
            }
            
            .mobile-consent-detail-type-tab,
            .mobile-consent-message-content-container {
                border-bottom: 1px solid #C6C6C6;
            }
            
            .mobile-consent-detail-type {
                margin-right: auto;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`}) {
                .mobile-consent-detail-type {
                    font-size: 12px !important;
                }
            }
            
            .arrow {
                border: solid #C7C7C7;
                border-width: 0 3px 3px 0;
                display: inline-block;
                padding: 3px;
            }
            
            .down {
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
            }
            
            .mobile-consent-message-content-container {
                transition: all 0.2s ease;
                position: relative;
                padding: 0px 10px;
            }
            
            .mobile-consent-message-description-container {
                flex-basis: 80%;
                overflow-y: scroll;
                margin-bottom: auto;
                max-height: 100%;
                height: 120px;
                margin: 0 0 0 10px;
            }
            
            .mobile-consent-message-description {
                font-size: 11pt;
                color: #000;
                margin: 0;
                font-weight: 500;
            }
            
            .mobile-accept-by-type-toggle-container {
                display: flex;
                align-items: center;
            }
            
            .is-expanded .mobile-consent-message-content-container {
                max-height: 1000px;
                min-height: 200px;
                overflow: visible;
                padding: 15px 10px 20px;
                opacity: 1;
            }
            
            .is-collapsed .mobile-consent-message-content-container {
                max-height: 0;
                min-height: 0;
                overflow: hidden;
                opacity: 0;
            }
            
            #full-consent-message-container {
                border-radius: 8px;
            }
            
            .highlight-mobile-type-tab {
                box-shadow: 0px 8px 15px #0000002c;
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `768px`}) {
                .modal-content-topic-container {
                    margin: 0 10px 10px;
                }
            }
            
            @media only screen and (max-width: ${this.previewMode ? `375px` : `599px`} {
                .consent-message-topic h3 {
                    font-size: 13px;
                }
                #mobile-consent-message-container h4 {
                    font-size: 13px;
                }
                #mobile-consent-message-container p {
                    font-size: 13px;
                }
                .is-expanded .mobile-consent-message-content-container {
                    padding: 10px;
                }
                .modal-content-topic-container {
                    margin: 0 0 10px;
                }
                .mobile-consent-message-description-container {
                    margin: 0px;
                }
                .mobile-modal-content-footer-container {
                    margin: 0px;
                }
            }
        </style>

        <div id="consent-noti">
            <div class="notification-close-btn close-btn"></div>
            <div class="container">
                <div id="consent-txt-container">
                    <div id="consent-txt">
                        <!-- consent text -->
                    </div>
                </div>
                <div id="consent-noti-btn-container">
                    <div id="language-btn-container"></div>
                    <div class="accept-btn">
                        <!-- button text -->
                    </div>
                </div>
            </div>
        </div>

        <div class="overlay" id="overlay"></div>

        <div class="consent-message-modal" id="modal">
            <div class="consent-message-topic-container">
                <div class="consent-message-topic">
                    <div class="pop-up-main-icon-container">
                        <svg class="pop-up-main-icon" viewBox="0 0 30 30">
                            <path fill="currentColor" d="M21,11C21,16.55 17.16,21.74 12,23C6.84,21.74 3,16.55 3,11V5L12,1L21,5V11M12,21C15.75,20 19,15.54 19,11.22V6.3L12,3.18V21Z" />
                        </svg>
                        <h2>${this.consentDetail.style_configuration.consent_detail.popup_main_icon.toUpperCase()}</h2>
                    </div>
                    <div class="consent-detail-title">
                        <h3>
                            <!-- consent detail title -->
                        </h3>
                    </div>
                </div>
                <div class="modal-lang-and-close-btn">
                    <div id="modal-language-btn-container"></div>
                    <div id="modal-close-btn" class="modal-close-btn close-btn"></div>
                </div>
            </div>
            <div class="consent-message-container" id="consent-message-container">
                <div id="consent-detail-type-tab-bar"></div>
                <div class="consent-message-content-container">
                    <div class="modal-content-topic-container">
                        <h4 id="modal-content-topic"></h4>
                    </div>
                    <div class="consent-message-description-container" id="modal-content-list"></div>
                    <div id="modal-content-footer-container">
                        <div id="full-version-btn-container">
                            <span class="modal-content-footer-text">
                                <!-- full version btn -->
                            </span>
                        </div>
                        <div id="accept-by-type-toggle-container">
                            <!-- Accept btn -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="consent-message-container hide-element" id="full-consent-message-container">
                <div class="consent-message-content-container">
                    <div class="modal-content-topic-container">
                        <h4 id="modal-full-content-topic"></h4>
                    </div>
                    <div class="consent-message-description-container" id="modal-full-content-list">
                        <!-- Full Description -->
                    </div>
                </div>
            </div>
            <div id="mobile-consent-message-container">
                <!-- Mobile Consent Message Body -->
            </div>
            <div id="consent-message-modal-footer-container">
                <div class="consent-message-btn-container" id="brief-description-consent-message-btn-container">
                    <button class="consent-message-save-button">Save Setting</button>
                    <button class="consent-message-accept-button">Accept All</button>
                </div>
                <div class="consent-message-btn-container hide-element" id="full-description-consent-message-btn-container">
                    <button class="consent-message-back-button"><img class="btn-icon-img" src="https://static.thenounproject.com/png/2916775-200.png" alt="go-back-icon" />Back</button>
                    <!-- <button class="consent-message-save-button"><img class="btn-icon-img" src="https://icon-library.com/images/icon-download-png/icon-download-png-20.jpg" alt="download-icon" />Download</button> -->
                </div>
            </div>
        </div>
    </div>`

  public render(): void {
    super.render()
    const template = document.createElement('template')
    template.innerHTML = this.template.trim()
    this.setDOM(template.content.firstChild as HTMLElement)
    this.init(false)
  }

  public renderOnElement(elementId: string): void {
    super.renderOnElement(elementId)
    const template = document.createElement('template')
    template.innerHTML = this.template.trim()
    this.setDOM(template.content.firstChild as HTMLElement)
    this.init(true)
    this.openModal()
  }

  public renderOnlyPopup(immediatelyPopup = false): void {
    if (!this.isRendered) {
      super.render()
      const template = document.createElement('template')
      template.innerHTML = this.template.trim()
      this.setDOM(template.content.firstChild as HTMLElement)
      this.init(true)

      if (immediatelyPopup) {
        this.openModal()
      }
    } else {
      this.openModal()
    }
  }

  public init = (isOnlyPopup: boolean): void => {
    this.languageButton()
    this.generateConsentTextForMobile()
    this.displayConsentBar(isOnlyPopup)
    this.generateConsentText()
    this.generateConsentDetailTypeTab()
    this.generateFullVersionButton()
    this.generateFullVersionButtonForMobile()
    this.generateAgreeToggleByType()
    this.generateAgreeToggleByTypeForMobile()
    this.changeLanguage(this.activeLanguage)
    this.changeTab(0, false)
    this.updateConsentAcceptationValue()

    this.setButtonEvent()
    this.closeModal()
  }

  public setButtonEvent = (): void => {
    // Assign onclick event on individual HTML tags
    const fullVersionBtnContainer = this.shadowRoot.querySelector('#full-version-btn-container')
    const fullVersionSpanTag = <HTMLCollectionOf<HTMLElement>>(
      fullVersionBtnContainer!.getElementsByClassName('modal-content-footer-text')
    )
    fullVersionSpanTag[0].onclick = this.showFullDescriptionToggle

    const backBtnContainer = this.shadowRoot.querySelector('#full-description-consent-message-btn-container')
    const backBtn = backBtnContainer!.getElementsByClassName(
      'consent-message-back-button'
    ) as HTMLCollectionOf<HTMLElement>
    backBtn[0].onclick = this.showFullDescriptionToggle

    const overlay = this.shadowRoot.querySelector<HTMLDivElement>('#overlay')!
    if (!this.previewMode) {
      overlay.onclick = this.closeModal
    } else {
      overlay.classList.add('hide-element')
    }

    if (!this.previewMode) {
      const closeBtn = this.shadowRoot.querySelector<HTMLDivElement>('#modal-close-btn')!
      closeBtn.onclick = this.closeModal

      const notiCloseBtnn = this.shadowRoot.querySelectorAll<HTMLDivElement>('.notification-close-btn')!
      notiCloseBtnn[0].onclick = this.closeNotiBar

      const briefDescBtnContainer = this.shadowRoot.querySelector('#brief-description-consent-message-btn-container')
      const saveSettingBtn = briefDescBtnContainer!.querySelectorAll<HTMLDivElement>('.consent-message-save-button')
      if (this.immediatelySubmit) {
        saveSettingBtn[0].onclick = this.submitConsent
      } else {
        saveSettingBtn[0].onclick = this.closeModal
      }

      const acceptAllBtn = briefDescBtnContainer!.querySelectorAll<HTMLDivElement>('.consent-message-accept-button')
      acceptAllBtn[0].onclick = () => this.acceptAllConsent(this.immediatelySubmit, this.closeModal)

      const notiBtnContainer = this.shadowRoot.querySelector('#consent-noti-btn-container')
      const notiAcceptBtnn = notiBtnContainer!.querySelectorAll<HTMLDivElement>('.accept-btn')
      notiAcceptBtnn[0].onclick = () => this.acceptAllConsent(true)
    }
  }

  public expandConsentBox = (idx: number, isOnMobile: boolean): void => {
    const consentMessageTopicContainer = this.shadowRoot.querySelectorAll('.mobile-consent-detail-type-tab')
    const consentMessageBox = this.shadowRoot.querySelectorAll('.mobile-consent-detail-container')

    for (let i = 0; i < consentMessageBox.length; i++) {
      if (i == idx) {
        if (isOnMobile) {
          consentMessageTopicContainer[i].classList.toggle('highlight-mobile-type-tab')
          consentMessageBox[i].classList.toggle('is-expanded')
          consentMessageBox[i].classList.toggle('is-collapsed')
        } else {
          consentMessageTopicContainer[i].classList.toggle('highlight-mobile-type-tab')
          consentMessageBox[i].classList.add('is-expanded')
          consentMessageBox[i].classList.remove('is-collapsed')
        }
      } else {
        consentMessageTopicContainer[i].classList.remove('highlight-mobile-type-tab')
        consentMessageBox[i].classList.add('is-collapsed')
        consentMessageBox[i].classList.remove('is-expanded')
      }
    }

    if (isOnMobile) {
      this.changeTab(idx, true)
    }
  }

  public languageButton = (): void => {
    if (this.consentDetail.setting.available_languages.length <= 1) {
      return
    }
    const langBtnGroupContainer = this.shadowRoot.querySelector('#language-btn-container')
    const modalLangBtnGroupContainer = this.shadowRoot.querySelector('#modal-language-btn-container')
    if (langBtnGroupContainer && modalLangBtnGroupContainer) {
      let i = 0
      if (this.consentDetail.setting.available_languages.length > 4) {
        // case bar consent
        const langSelect = document.createElement('select')
        langSelect.options.selectedIndex = 0
        langSelect.id = `select_lang_bar`
        langSelect.name = `select_lang_bar`
        langSelect.classList.add('select-language')
        for (const lang of this.consentDetail.setting.available_languages) {
          const option = document.createElement('option')
          option.value = lang.toLowerCase()
          option.text = lang.toUpperCase()
          langSelect.appendChild(option)
        }
        langSelect.onchange = () =>
          this.changeLanguage(langSelect.options[langSelect.selectedIndex].value.toLowerCase())
        langBtnGroupContainer.appendChild(langSelect)

        const langSelectModal = document.createElement('select')
        langSelectModal.options.selectedIndex = 0
        langSelectModal.id = `select_lang_modal`
        langSelectModal.name = `select_lang_modal`
        langSelectModal.classList.add('select-language')
        for (const lang of this.consentDetail.setting.available_languages) {
          const option = document.createElement('option')
          option.value = lang.toLowerCase()
          option.text = lang.toUpperCase()
          langSelectModal.appendChild(option)
        }
        langSelectModal.onchange = () =>
          this.changeLanguage(langSelectModal.options[langSelectModal.selectedIndex].value.toLowerCase())

        modalLangBtnGroupContainer.appendChild(langSelectModal)
      } else if (this.consentDetail.setting.available_languages.length > 2) {
        // case modal consent
        const langSelectModal = document.createElement('select')
        langSelectModal.options.selectedIndex = 0
        langSelectModal.id = `select_lang_modal`
        langSelectModal.name = `select_lang_modal`
        langSelectModal.classList.add('select-language')
        for (const lang of this.consentDetail.setting.available_languages) {
          const option = document.createElement('option')
          option.value = lang.toLowerCase()
          option.text = lang.toUpperCase()
          langSelectModal.appendChild(option)
        }
        langSelectModal.onchange = () =>
          this.changeLanguage(langSelectModal.options[langSelectModal.selectedIndex].value.toLowerCase())

        modalLangBtnGroupContainer.appendChild(langSelectModal)

        for (const lang of this.consentDetail.setting.available_languages) {
          const langBtnContainer = document.createElement('span')
          langBtnContainer.innerHTML = lang.toUpperCase()
          langBtnContainer.onclick = () => this.changeLanguage(lang.toLowerCase())
          langBtnContainer.classList.add('language-btn')

          if (lang == this.consentDetail.setting.default_language) {
            langBtnContainer.classList.add('active-language-btn')
          }

          langBtnGroupContainer.appendChild(langBtnContainer)

          if (this.consentDetail.setting.available_languages.length - i > 1) {
            const divider = document.createElement('span')
            divider.innerHTML = '&nbsp;&nbsp;|&nbsp;&nbsp;'
            langBtnGroupContainer.appendChild(divider)
          }

          // For modal
          // const modalLangBtnContainer = document.createElement('div')
          // modalLangBtnContainer.classList.add('modal-language-btn')
          // modalLangBtnContainer.onclick = () => this.changeLanguage(lang.toLowerCase())
          // modalLangBtnContainer.innerHTML = lang.toUpperCase()

          // modalLangBtnGroupContainer.appendChild(modalLangBtnContainer)

          i += 1
        }
      } else {
        for (const lang of this.consentDetail.setting.available_languages) {
          const langBtnContainer = document.createElement('span')
          langBtnContainer.innerHTML = lang.toUpperCase()
          langBtnContainer.onclick = () => this.changeLanguage(lang.toLowerCase())
          langBtnContainer.classList.add('language-btn')

          if (lang == this.consentDetail.setting.default_language) {
            langBtnContainer.classList.add('active-language-btn')
          }

          langBtnGroupContainer.appendChild(langBtnContainer)

          if (this.consentDetail.setting.available_languages.length - i > 1) {
            const divider = document.createElement('span')
            divider.innerHTML = '&nbsp;&nbsp;|&nbsp;&nbsp;'
            langBtnGroupContainer.appendChild(divider)
          }

          // For modal
          const modalLangBtnContainer = document.createElement('div')
          modalLangBtnContainer.classList.add('modal-language-btn')
          modalLangBtnContainer.onclick = () => this.changeLanguage(lang.toLowerCase())
          modalLangBtnContainer.innerHTML = lang.toUpperCase()

          modalLangBtnGroupContainer.appendChild(modalLangBtnContainer)

          i += 1
        }
      }
    }
  }

  public generateConsentTextForMobile = (): void => {
    const mobileAllConsentMessagesContainer = this.shadowRoot.querySelector('#mobile-consent-message-container')
    if (mobileAllConsentMessagesContainer) {
      let i = 0
      for (const item in this.consentDetailType) {
        if (this.consentDetail.setting[item].is_enabled) {
          const index = i

          // Top container of consent detail type
          const consentMessageByTypeContainer = document.createElement('div')
          consentMessageByTypeContainer.classList.add('mobile-consent-detail-container')
          consentMessageByTypeContainer.classList.add('is-collapsed')

          // Consent detail type tab container
          const consentDetailTypeTabContainer = document.createElement('div')
          consentDetailTypeTabContainer.classList.add('consent-detail-type-tab')
          consentDetailTypeTabContainer.classList.add('mobile-consent-detail-type-tab')
          consentDetailTypeTabContainer.onclick = () => this.expandConsentBox(index, true)

          // Active icon
          const activeIconContainer = document.createElement('div')
          activeIconContainer.classList.add('active-consent-type-icon-container')

          const checkIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
          checkIcon.classList.add('active-consent-type-icon')
          checkIcon.setAttributeNS(null, 'viewBox', '0 0 24 24')

          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
          path.setAttribute('d', 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z')
          path.setAttribute('fill', 'currentColor')

          checkIcon.appendChild(path)
          activeIconContainer.appendChild(checkIcon)

          // Type title
          const consentDetailTypeTitle = document.createElement('span')
          consentDetailTypeTitle.classList.add('consent-detail-type')
          consentDetailTypeTitle.classList.add('mobile-consent-detail-type')
          consentDetailTypeTitle.innerHTML = this.consentDetailType[item].label

          // Down arrow
          const downArrowIconContainer = document.createElement('div')
          const downArrowIcon = document.createElement('i')
          downArrowIcon.classList.add('arrow')
          downArrowIcon.classList.add('down')
          downArrowIconContainer.appendChild(downArrowIcon)

          // Assign child to parent containner
          consentDetailTypeTabContainer.appendChild(activeIconContainer)
          consentDetailTypeTabContainer.appendChild(consentDetailTypeTitle)
          consentDetailTypeTabContainer.appendChild(downArrowIconContainer)

          consentMessageByTypeContainer.appendChild(consentDetailTypeTabContainer)

          // Consent message container
          const mobileConsentContentContainer = document.createElement('div')
          mobileConsentContentContainer.classList.add('mobile-consent-message-content-container')

          // Topic
          const typeTopicContainner = document.createElement('div')
          typeTopicContainner.classList.add('modal-content-topic-container')
          const typeTopic = document.createElement('h4')
          typeTopic.classList.add('mobile-modal-content-topic')
          typeTopic.innerHTML = this.consentDetailType[item].label

          typeTopicContainner.appendChild(typeTopic)

          // Body
          const consentDescriptionContainer = document.createElement('div')
          consentDescriptionContainer.classList.add('mobile-consent-message-description-container')
          const consentDescriptionBody = document.createElement('p')
          consentDescriptionBody.classList.add('mobile-consent-message-description')
          consentDescriptionBody.innerHTML =
            this.consentDetail.setting[item].brief_description[this.activeLanguage] || ''

          consentDescriptionContainer.appendChild(consentDescriptionBody)

          // Footer
          const modalFooterContainer = document.createElement('div')
          modalFooterContainer.classList.add('mobile-modal-content-footer-container')

          const fullVersionBtnConntainer = document.createElement('div')
          fullVersionBtnConntainer.classList.add('mobile-full-version-btn-container')

          const acceptToggleConntainer = document.createElement('div')
          acceptToggleConntainer.classList.add('mobile-accept-by-type-toggle-container')

          modalFooterContainer.appendChild(fullVersionBtnConntainer)
          modalFooterContainer.appendChild(acceptToggleConntainer)

          // Assign child to parent containner
          mobileConsentContentContainer.appendChild(typeTopicContainner)
          mobileConsentContentContainer.appendChild(consentDescriptionContainer)
          mobileConsentContentContainer.appendChild(modalFooterContainer)

          consentMessageByTypeContainer.appendChild(mobileConsentContentContainer)

          mobileAllConsentMessagesContainer.appendChild(consentMessageByTypeContainer)

          i += 1
        }
      }
    }
  }

  public generateMobileConsentBriefDescriptionByLang = (): void => {
    const consentDescriptionBody = this.shadowRoot.querySelectorAll('.mobile-consent-message-description')
    // const consentDetailType = this.consentDetail.consent_message_type === 'tracking_type' ? this.trackingConsentDetailType : this.contactingTypeDetailOptions;

    let i = 0
    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        consentDescriptionBody[i].innerHTML =
          this.consentDetail.setting[item].brief_description[this.activeLanguage] || ''

        i += 1
      }
    }
  }

  public displayConsentBar = (isOnlyPopupDisplayed: boolean): void => {
    const consentBarContainer = this.shadowRoot.querySelector('#consent-noti')
    if (isOnlyPopupDisplayed) {
      consentBarContainer!.classList.add('hide-element')
    }
  }

  public generateConsentText = (): void => {
    const consentText = this.shadowRoot.querySelector('#consent-txt')!
    const acceptBtnContainer = this.shadowRoot.querySelector('#consent-noti-btn-container')
    const acceptBtn = acceptBtnContainer!.querySelectorAll<HTMLDivElement>('.accept-btn')!
    const consentDetailTitleContainer = this.shadowRoot.querySelectorAll('.consent-detail-title')

    consentText.innerHTML = this.consentDetail.setting.display_text[this.activeLanguage]
    acceptBtn[0].innerHTML = this.consentDetail.setting.accept_button_text[this.activeLanguage]

    const consentDetailTitle = consentDetailTitleContainer[0].getElementsByTagName('h3')
    consentDetailTitle[0].innerHTML = this.consentDetail.setting.consent_detail_title[this.activeLanguage]

    const moreInfoBtn = document.createElement('span')
    moreInfoBtn.innerHTML = this.consentDetail.setting.more_info.display_text[this.activeLanguage] || 'More Info'
    moreInfoBtn.classList.add('more-info-link')

    const moreInfoLink = document.createElement('a')

    if (this.consentDetail.setting.more_info.is_custom_url_enabled) {
      moreInfoLink.href = this.consentDetail.setting.more_info.custom_url[this.activeLanguage] || '#'
      moreInfoLink.target = '_blank'
      moreInfoLink.appendChild(moreInfoBtn)
      consentText.appendChild(moreInfoLink)
    } else {
      moreInfoBtn.onclick = this.openModal
      consentText.appendChild(moreInfoBtn)
    }
  }

  public generateConsentDetailTypeTab = async (): Promise<void> => {
    const checkboxContainer = this.shadowRoot.querySelector('#consent-detail-type-tab-bar')!
    let i = 0
    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        const index = i

        const typeTabContainer = document.createElement('div')
        typeTabContainer.classList.add('consent-detail-type-tab')
        typeTabContainer.onclick = () => this.changeTab(index, false)

        const activeIconContainer = document.createElement('div')
        activeIconContainer.classList.add('active-consent-type-icon-container')

        const checkIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
        checkIcon.classList.add('active-consent-type-icon')
        checkIcon.setAttributeNS(null, 'viewBox', '0 0 24 24')

        const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
        path.setAttribute('d', 'M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z')
        path.setAttribute('fill', 'currentColor')

        checkIcon.appendChild(path)

        const typeName = document.createElement('span')
        typeName.classList.add('consent-detail-type')
        typeName.innerHTML = this.consentDetailType[item].label

        activeIconContainer.appendChild(checkIcon)
        typeTabContainer.appendChild(activeIconContainer)
        typeTabContainer.appendChild(typeName)

        checkboxContainer.appendChild(typeTabContainer)

        i += 1
      }
    }
  }

  public generateFullVersionButton = (): void => {
    const fullVersionBtnContainer = this.shadowRoot.querySelector('#full-version-btn-container')!
    const fullVersionSpanTag = fullVersionBtnContainer.getElementsByClassName(
      'modal-content-footer-text'
    ) as HTMLCollectionOf<HTMLElement>

    fullVersionSpanTag[0].onclick = this.showFullDescriptionToggle

    let i = 0
    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        if (this.selectedConsentDetailTypeIdx == i) {
          if (this.consentDetail.setting[item].is_full_description_enabled) {
            fullVersionSpanTag[0].innerHTML = 'Full Version'
            fullVersionSpanTag[0].style.display = 'block'
          } else {
            fullVersionSpanTag[0].innerHTML = ''
            fullVersionSpanTag[0].style.display = 'none'
          }
          break
        }
        i += 1
      }
    }
  }

  public generateFullVersionButtonForMobile = (): void => {
    const mobileFullVersionBtnContainer = this.shadowRoot.querySelectorAll('.mobile-full-version-btn-container')

    let i = 0
    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        const fullVersionSpanTag = document.createElement('span')
        fullVersionSpanTag.classList.add('modal-content-footer-text')
        if (this.consentDetail.setting[item].is_full_description_enabled) {
          fullVersionSpanTag.innerHTML = 'Full Version'
          fullVersionSpanTag.onclick = this.showFullDescriptionToggle
        } else {
          fullVersionSpanTag.innerHTML = ''
          fullVersionSpanTag.style.display = 'none'
        }

        mobileFullVersionBtnContainer[i].appendChild(fullVersionSpanTag)
        i += 1
      }
    }
  }

  public generateAgreeToggleByType = (): void => {
    const checkboxContainer = this.shadowRoot.querySelector<HTMLDivElement>('#accept-by-type-toggle-container')
    let i = 0
    // let isAlwaysAccepted = false
    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        const index = i
        // isAlwaysAccepted = this.consentDetailType[item].isAlwaysAccepted

        const checkbox = document.createElement('input')
        checkbox.type = 'checkbox'
        checkbox.id = `checkbox_${item}`
        checkbox.name = `test-input-group`
        checkbox.value = `${item}`
        checkbox.classList.add('accept-by-type-toggle')
        checkbox.checked = true
        checkbox.onclick = () => this.acceptToggle(index, item)

        const label = document.createElement('label')
        label.classList.add('accept-by-type-switch')

        if (this.selectedConsentDetailTypeIdx == index) {
          label.style.display = 'block'
        } else {
          label.style.display = 'none'
        }

        const slider = document.createElement('span')
        slider.classList.add('slider')
        slider.classList.add('round')

        label.appendChild(checkbox)
        label.appendChild(slider)
        checkboxContainer!.appendChild(label)

        i += 1
      }
    }
    const checkBoxLabel = document.createElement('span')
    checkBoxLabel.innerHTML = 'Accept'
    checkBoxLabel.classList.add('modal-content-footer-text')
    checkboxContainer!.appendChild(checkBoxLabel)

    // if(isAlwaysAccepted) {
    //     checkboxContainer!.style.display = 'none'
    // } else {
    //     checkboxContainer!.style.display = 'block'
    // }
  }

  public generateAgreeToggleByTypeForMobile = (): void => {
    const mobilecheckboxContainer = this.shadowRoot.querySelectorAll<HTMLDivElement>(
      '.mobile-accept-by-type-toggle-container'
    )
    let i = 0
    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        const index = i

        const checkbox = document.createElement('input')
        checkbox.type = 'checkbox'
        checkbox.id = `mobile_checkbox_${item}`
        checkbox.name = `mobile_${item}`
        checkbox.value = `${item}`
        checkbox.classList.add('accept-by-type-toggle')
        checkbox.checked = true
        checkbox.onclick = () => this.acceptToggle(index, item)

        const label = document.createElement('label')
        label.classList.add('accept-by-type-switch')

        const slider = document.createElement('span')
        slider.classList.add('slider')
        slider.classList.add('round')

        label.appendChild(checkbox)
        label.appendChild(slider)

        mobilecheckboxContainer[index].appendChild(label)

        const checkBoxLabel = document.createElement('span')
        checkBoxLabel.innerHTML = 'Accept'
        checkBoxLabel.classList.add('modal-content-footer-text')
        mobilecheckboxContainer[index].appendChild(checkBoxLabel)

        if (this.consentDetailType[item].isAlwaysAccepted) {
          mobilecheckboxContainer[index].style.display = 'none'
        }

        i += 1
      }
    }
  }

  public generateConsentDetailByType = (): void => {
    const modelContent = this.shadowRoot.querySelector('#modal-content-list')
    if (modelContent) {
      while (modelContent.firstChild) {
        modelContent.removeChild(modelContent.firstChild)
      }

      let i = 0
      for (const item in this.consentDetailType) {
        if (this.consentDetail.setting[item].is_enabled) {
          const contentContainer = document.createElement('div')
          contentContainer.classList.add('modal-content')

          if (this.selectedConsentDetailTypeIdx == i) {
            contentContainer.style.display = 'block'
          } else {
            contentContainer.style.display = 'none'
          }

          const contentParagraph = document.createElement('p')
          contentParagraph.innerHTML = this.consentDetail.setting[item].brief_description[this.activeLanguage] || ''

          contentContainer.appendChild(contentParagraph)
          modelContent.appendChild(contentContainer)

          i += 1
        }
      }
    }
  }

  public generateConsentFullDetailByType = (): void => {
    const modelFullContent = this.shadowRoot.querySelector('#modal-full-content-list')
    if (modelFullContent) {
      while (modelFullContent.firstChild) {
        modelFullContent.removeChild(modelFullContent.firstChild)
      }

      let i = 0
      for (const item in this.consentDetailType) {
        if (this.consentDetail.setting[item].is_enabled) {
          const contentContainer = document.createElement('div')
          contentContainer.classList.add('full-modal-content')

          if (this.selectedConsentDetailTypeIdx == i) {
            contentContainer.style.display = 'block'
          } else {
            contentContainer.style.display = 'none'
          }

          const contentParagraph = document.createElement('p')
          contentParagraph.innerHTML = this.consentDetail.setting[item].full_description[this.activeLanguage] || ''

          contentContainer.appendChild(contentParagraph)
          modelFullContent.appendChild(contentContainer)

          i += 1
        }
      }
    }
  }

  public changeLanguage = (lang: string): void => {
    const langBtn = this.shadowRoot.querySelectorAll('.language-btn')
    const modalLangBtn = this.shadowRoot.querySelectorAll('.modal-language-btn')
    this.activeLanguage = lang

    for (let i = 0; i < langBtn.length; i++) {
      const presentLang = langBtn[i].innerHTML.trim().toLowerCase()
      if (lang.toLowerCase() == presentLang) {
        //this.activeLanguage = presentLang

        langBtn[i].classList.add('active-language-btn')
        if (langBtn.length < 3) {
          modalLangBtn[i].classList.add('active-modal-language-btn')
          modalLangBtn[i].classList.remove('inactive-language-btn')
        }
      } else {
        langBtn[i].classList.remove('active-language-btn')
        if (langBtn.length < 3) {
          modalLangBtn[i].classList.remove('active-modal-language-btn')
          modalLangBtn[i].classList.add('inactive-language-btn')
        }
      }
    }

    this.generateConsentText()
    this.generateConsentDetailByType()
    this.generateMobileConsentBriefDescriptionByLang()
    this.generateConsentFullDetailByType()
  }

  public changeTab = (idx: number, isOnMobile: boolean): void => {
    const tabs = this.shadowRoot.querySelectorAll<HTMLDivElement>('.consent-detail-type-tab')
    const consentDetailTypeTag = this.shadowRoot.querySelectorAll<HTMLSpanElement>('.consent-detail-type')
    const contents = this.shadowRoot.querySelectorAll<HTMLDivElement>('.modal-content')
    const fullContents = this.shadowRoot.querySelectorAll<HTMLDivElement>('.full-modal-content')
    const contentTopic = this.shadowRoot.querySelector<HTMLDivElement>('#modal-content-topic')
    const fullContentTopic = this.shadowRoot.querySelector<HTMLDivElement>('#modal-full-content-topic')
    const checkboxContainer = this.shadowRoot.querySelector<HTMLDivElement>('#accept-by-type-toggle-container')
    const acceptSwitch = checkboxContainer!.getElementsByClassName(
      'accept-by-type-switch'
    ) as HTMLCollectionOf<HTMLLabelElement>

    this.selectedConsentDetailTypeIdx = idx

    for (let i = 0; i < contents.length; i++) {
      if (i === idx) {
        contentTopic!.innerHTML = consentDetailTypeTag[i].innerHTML
        fullContentTopic!.innerHTML = consentDetailTypeTag[i].innerHTML
        tabs[i].classList.add('modal-tab-active')
        contents[i].style.display = 'block'
        fullContents[i].style.display = 'block'

        let typeSequence = 0
        for (const item in this.consentDetailType) {
          if (this.consentDetail.setting[item].is_enabled) {
            if (typeSequence == idx) {
              checkboxContainer!.style.display = this.consentDetailType[item].isAlwaysAccepted ? 'none' : 'flex'
              break
            }
            typeSequence += 1
          }
        }
        acceptSwitch[i].style.display = 'block'
      } else {
        tabs[i].classList.remove('modal-tab-active')
        contents[i].style.display = 'none'
        fullContents[i].style.display = 'none'
        acceptSwitch[i].style.display = 'none'
      }
    }

    this.generateFullVersionButton()

    if (!isOnMobile) {
      this.expandConsentBox(idx, false)
    }
  }

  public showFullDescriptionToggle = (): void => {
    const briefDescriptionContainer = this.shadowRoot.querySelector('#consent-message-container')
    const fullDescriptionContainer = this.shadowRoot.querySelector('#full-consent-message-container')
    const briefDescriptionBtnContainer = this.shadowRoot.querySelector(
      '#brief-description-consent-message-btn-container'
    )
    const fullDescriptionBtnContainer = this.shadowRoot.querySelector('#full-description-consent-message-btn-container')

    const mobileConsentMessageContainer = this.shadowRoot.querySelector('#mobile-consent-message-container')

    mobileConsentMessageContainer!.classList.toggle('hide-element')

    briefDescriptionContainer?.classList.toggle('hide-element')
    fullDescriptionContainer?.classList.toggle('hide-element')

    briefDescriptionBtnContainer?.classList.toggle('hide-element')
    fullDescriptionBtnContainer?.classList.toggle('hide-element')
  }

  public acceptToggle = (idx: number, typeName: string): void => {
    const webConsentMessageContainer = this.shadowRoot.querySelector('#consent-detail-type-tab-bar')
    const mobileConsentMessageContainer = this.shadowRoot.querySelector('#mobile-consent-message-container')

    const consentDetailTypeActiveIcon = webConsentMessageContainer!.querySelectorAll<HTMLImageElement>(
      '.active-consent-type-icon'
    )
    const mobileConsentDetailTypeActiveIcon = mobileConsentMessageContainer!.querySelectorAll<HTMLImageElement>(
      '.active-consent-type-icon'
    )

    consentDetailTypeActiveIcon[idx].classList.toggle('hide-element')
    mobileConsentDetailTypeActiveIcon[idx].classList.toggle('hide-element')
    this.shadowRoot.querySelector<HTMLInputElement>(`#checkbox_${typeName}`)!.checked = !consentDetailTypeActiveIcon[
      idx
    ].classList.contains('hide-element')
    this.shadowRoot.querySelector<HTMLInputElement>(
      `#mobile_checkbox_${typeName}`
    )!.checked = !mobileConsentDetailTypeActiveIcon[idx].classList.contains('hide-element')

    this.callbackTriggering()
  }

  public acceptAllConsent = (submitNow: boolean, func?: () => void): void => {
    let i = 0

    const webConsentMessageContainer = this.shadowRoot.querySelector('#consent-detail-type-tab-bar')
    const mobileConsentMessageContainer = this.shadowRoot.querySelector('#mobile-consent-message-container')

    const consentDetailTypeActiveIcon = webConsentMessageContainer!.querySelectorAll<HTMLImageElement>(
      '.active-consent-type-icon'
    )
    const mobileConsentDetailTypeActiveIcon = mobileConsentMessageContainer!.querySelectorAll<HTMLImageElement>(
      '.active-consent-type-icon'
    )

    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        const index = i

        consentDetailTypeActiveIcon[index].classList.remove('hide-element')
        mobileConsentDetailTypeActiveIcon[index].classList.remove('hide-element')
        this.shadowRoot.querySelector<HTMLInputElement>(`#checkbox_${item}`)!.checked = true
        this.shadowRoot.querySelector<HTMLInputElement>(`#mobile_checkbox_${item}`)!.checked = true

        i += 1
      }
    }

    this.callbackTriggering()

    if (submitNow) {
      this.submitConsent()
    }

    func && func()
  }

  public unAcceptAllConsent = (): void => {
    let i = 0

    const webConsentMessageContainer = this.shadowRoot.querySelector('#consent-detail-type-tab-bar')
    const mobileConsentMessageContainer = this.shadowRoot.querySelector('#mobile-consent-message-container')

    const consentDetailTypeActiveIcon = webConsentMessageContainer!.querySelectorAll<HTMLImageElement>(
      '.active-consent-type-icon'
    )
    const mobileConsentDetailTypeActiveIcon = mobileConsentMessageContainer!.querySelectorAll<HTMLImageElement>(
      '.active-consent-type-icon'
    )

    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        const index = i

        consentDetailTypeActiveIcon[index].classList.add('hide-element')
        mobileConsentDetailTypeActiveIcon[index].classList.add('hide-element')
        this.shadowRoot.querySelector<HTMLInputElement>(`#checkbox_${item}`)!.checked = false
        this.shadowRoot.querySelector<HTMLInputElement>(`#mobile_checkbox_${item}`)!.checked = false

        i += 1
      }
    }

    this.callbackTriggering()
  }

  public acceptSpecificConsent = (permissionBody: Record<string, boolean>): void => {
    const webConsentMessageContainer = this.shadowRoot.querySelector('#consent-detail-type-tab-bar')
    const mobileConsentMessageContainer = this.shadowRoot.querySelector('#mobile-consent-message-container')

    const consentDetailTypeActiveIcon = webConsentMessageContainer!.querySelectorAll<HTMLImageElement>(
      '.active-consent-type-icon'
    )
    const mobileConsentDetailTypeActiveIcon = mobileConsentMessageContainer!.querySelectorAll<HTMLImageElement>(
      '.active-consent-type-icon'
    )

    for (const topic in permissionBody) {
      let i = 0
      for (const item in this.consentDetailType) {
        if (this.consentDetail.setting[item].is_enabled) {
          const index = i

          if (topic == item) {
            if (permissionBody[topic]) {
              consentDetailTypeActiveIcon[index].classList.remove('hide-element')
              mobileConsentDetailTypeActiveIcon[index].classList.remove('hide-element')
            } else {
              consentDetailTypeActiveIcon[index].classList.add('hide-element')
              mobileConsentDetailTypeActiveIcon[index].classList.add('hide-element')
            }

            this.shadowRoot.querySelector<HTMLInputElement>(`#checkbox_${item}`)!.checked = permissionBody[topic]
              ? true
              : false
            this.shadowRoot.querySelector<HTMLInputElement>(`#mobile_checkbox_${item}`)!.checked = permissionBody[topic]
              ? true
              : false

            break
          }

          i += 1
        }
      }
    }

    this.callbackTriggering()
  }

  public updateConsentAcceptationValue = (): void => {
    for (const item in this.consentDetailType) {
      if (this.consentDetail.setting[item].is_enabled) {
        this.assign(
          this.updatedConsentAcceptation.permission,
          [item],
          this.shadowRoot.querySelector<HTMLInputElement>(`#checkbox_${item}`)!.checked
        )
      }
    }
  }

  public submitConsent = async (): Promise<ITrackerResponse | null> => {
    if (!this.previewMode) {
      this.closeModal()
      this.closeNotiBar()

      let res: ITrackerResponse
      try {
        if (this.submitConsentPermission) {
          res = await this.submitConsentPermission(this.updatedConsentAcceptation)
          return res
        }
      } catch (e) {
        throw e
      }
    }

    return null
  }

  private callbackTriggering = (): void => {
    const prev = {
      consent_message_id: this.updatedConsentAcceptation.consent_message_id,
      version: this.updatedConsentAcceptation.version,
      permission: { ...this.updatedConsentAcceptation.permission },
    }

    this.updateConsentAcceptationValue()

    const state = {
      consent_message_id: this.updatedConsentAcceptation.consent_message_id,
      version: this.updatedConsentAcceptation.version,
      permission: { ...this.updatedConsentAcceptation.permission },
    }

    this.callback && this.callback(prev, state)
  }

  public openModal = (): void => {
    this.shadowRoot.querySelector<HTMLDivElement>('#overlay')!.style.display = 'block'
    this.shadowRoot.querySelector<HTMLDivElement>('#modal')!.style.display = 'flex'
  }

  public closeModal = (): void => {
    this.shadowRoot.querySelector<HTMLDivElement>('#overlay')!.style.display = 'none'
    this.shadowRoot.querySelector<HTMLDivElement>('#modal')!.style.display = 'none'
  }

  public closeNotiBar = (): void => {
    this.shadowRoot.querySelector<HTMLDivElement>('#consent-noti')!.style.display = 'none'
    window.localStorage.setItem('latest_get_customer_consent', new Date().toISOString())
  }

  public removePopup = (): void => {
    this.close()
  }

  public assign = (obj: any, keyPath: any, value: any): void => {
    const lastKeyIndex = keyPath.length - 1
    for (let i = 0; i < lastKeyIndex; ++i) {
      const key = keyPath[i]
      if (!(key in obj)) {
        obj[key] = {}
      }
      obj = obj[key]
    }
    obj[keyPath[lastKeyIndex]] = value
  }
}
