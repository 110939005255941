interface PopupTemplatePropTypes {
  html: string
  width: string
  backdropOpacity: number
  isBorderless: boolean
}

export const popupTemplate = ({ html, width, backdropOpacity, isBorderless }: PopupTemplatePropTypes): string => {
  return `<div>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css"/>
    <style>

    .popup {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 2147483647; /* Sit on top */
      left: 0;
      top: 0;
      padding: 50px 50px;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
    }

    .popup-content {
      z-index: 2;
      position: relative;
      background-color: #fefefe;
      width: ${width}; /* Could be more or less, depending on screen size */
      outline: 0;
      max-width: 92%;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 100%;
      -ms-overflow-style: none;  /* IE and Edge */
    }

    .body {
      padding: ${isBorderless ? 0 : '20px'};
      border-radius: 5px;
      outline: 0;
    }

    @media (max-width: 575px) {
      .popup-content {
        width: 100%;
      }
      .popup {
        padding: ${isBorderless ? 0 : '50px 15px'};
      }
    }

    .attention-popup-backdrop {
      overflow: hidden;
      background-color: rgba(0, 0, 0, ${backdropOpacity / 100});
      width: 100vw;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1;
      visibility: visible;
      transition: opacity 0.2s ease-in;
    }

    .attention-popup-close {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      background-color: #ffffff;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      right: 6px;
      top: 6px;
      z-index: 2001;
      margin: 0;
      padding: 0;
      cursor: pointer
    }
    </style>
    <div class="popup">
      <div
        class="attention-popup-backdrop"
      ></div>
      <div class="popup-content">
        <div class="attention-popup-close">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" width="8px" height="8px" viewBox="0 0 348.333 348.334" style="enable-background:new 0 0 348.333 348.334;" xml:space="preserve" class="">
            <g>
              <path d="M336.559,68.611L231.016,174.165l105.543,105.549c15.699,15.705,15.699,41.145,0,56.85   c-7.844,7.844-18.128,11.769-28.407,11.769c-10.296,0-20.581-3.919-28.419-11.769L174.167,231.003L68.609,336.563   c-7.843,7.844-18.128,11.769-28.416,11.769c-10.285,0-20.563-3.919-28.413-11.769c-15.699-15.698-15.699-41.139,0-56.85   l105.54-105.549L11.774,68.611c-15.699-15.699-15.699-41.145,0-56.844c15.696-15.687,41.127-15.687,56.829,0l105.563,105.554   L279.721,11.767c15.705-15.687,41.139-15.687,56.832,0C352.258,27.466,352.258,52.912,336.559,68.611z" data-original="#000000" class="active-path" data-old_color="#000000" fill="#000000"/>
            </g>
          </svg>
        </div>
        ${html}
      </div>
    </div>
  </div>`
}
