export default class Queue<T> {
  private queue: T[]

  constructor() {
    this.queue = []
  }

  public get isEmpty(): boolean {
    return this.queue.length <= 0
  }

  public enqueue(t: T): void {
    this.queue.push(t)
  }

  public dequeue(): T | undefined {
    return this.queue.shift()
  }
}

export enum TaskType {
  TrackerSingle,
  TrackerBulk,
}

export class Task {
  constructor(public pubclitype: TaskType, public data: any) {}
}
