import { IPopupConfig } from './types'
import { POPUP_CONFIG, EVENT } from './consts'

export const popupTemplate = (config: IPopupConfig) => {
  const title = config.title || POPUP_CONFIG.TITLE
  const message = config.message || POPUP_CONFIG.MESSAGE
  const buttonText = config.button_text || POPUP_CONFIG.BUTTON_TEXT
  const navigationText = config.navigation_text || POPUP_CONFIG.NAVIGATION_TEXT
  const primary = config.primary || POPUP_CONFIG.PRIMARY
  const secondary = config.secondary || POPUP_CONFIG.SECONDARY
  const primaryFont = config.primary_font || primary
  const secondaryFont = config.secondary_font || secondary
  const animateTypeBtn = config.animate_type_btn || POPUP_CONFIG.ANIMATE_TYPE_BTN

  return `
    <div>
      <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet">
      <style>
        * {
          box-sizing: border-box !important;
          font-family: 'Roboto', sans-serif;
        }
        body {
          background: #000;
        }
        pre {
          font-size: 16px;
          margin: 0;
          padding: 8px;
          white-space: pre-wrap;
        }
        #noti-info-client.horizontal{
          position: fixed;
          right: 1%;
          top: 2.6%;
          z-index: 999999;
          /*Absoluto*/
          width: 330px; /* tamanho da notificação */
          color: #5E7181;
          font-size: 12px;
        }
        #noti-info-client.vertical{
          position: fixed;
          right: 5%;
          top: 2.6%;
          z-index: 999999;
          /*Absoluto*/
          width: 330px; /* tamanho da notificação */
          color: #5E7181;
          font-size: 12px;
        }
        #noti-info-client #panel{
          z-index: 99;
          display: block;
          position: fixed;
          background-color: ${primary};
          width: 50px;
          height: 50px;
          border-radius: 50%;
          bottom: 20px;
          right: 20px;
          margin: 10px 10px;
          cursor: pointer;
          -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,.4);
          -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,.4);
          -ms-box-shadow: 0px 2px 3px 0px rgba(0,0,0,.4);
          box-shadow: 0px 2px 3px 0px rgba(0,0,0,.4);
        }
        #noti-info-client #panel .icon {
          display: inline-block;
          width: 20px;
          height: 20px;
          stroke-width: 0;
          stroke: currentColor;
          fill: ${secondaryFont};
          margin: auto;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          transform-origin: 50% 0%;
          -moz-transform-origin: 50% 0%;
          -webkit-transform-origin: 50% 0%;
          -ms-transform-origin: 50% 0%;
        }
        .bell-alert #panel::before{
          /* Keyframe */
          /* animation : sino-pulsa 1.2s infinite;
          -webkit-animation : sino-pulsa 1.2s infinite;
          -moz-animation : sino-pulsa 1.2s infinite;
          -ms-animation : sino-pulsa 1.2s infinite; */
        }
        .bell-alert #panel{
          /* background-color: 	${primary} !important; */
        }
        .bell-alert .icon{
          /* fill: #FBF9FC !important; */
        }
        .active-btn-to{
          /*transform: translateX(-166px);*/
          animation: 1s animate-btn-to-${animateTypeBtn} ease-in-out !important;
          -moz-animation: 1s animate-btn-to-${animateTypeBtn} ease-in-out !important;
          -webkit-animation: 1s animate-btn-to-${animateTypeBtn} ease-in-out !important;
          -ms-animation: 1s animate-btn-to-${animateTypeBtn} ease-in-out !important;

          animation-fill-mode: forwards !important;
          -webkit-animation-fill-mode: forwards !important;
          -moz-animation-fill-mode: forwards !important;
          -ms-animation-fill-mode: forwards !important;
        }

        .active-btn-from{
          animation: 1s animate-btn-from-${animateTypeBtn} ease-in-out;
          -webkit-animation: 1s animate-btn-from-${animateTypeBtn} ease-in-out;
          -moz-animation: 1s animate-btn-from-${animateTypeBtn} ease-in-out;
          -ms-animation: 1s animate-btn-from-${animateTypeBtn} ease-in-out;

          animation-fill-mode: forwards;
          -webkit-animation-fill-mode: forwards;
          -moz-animation-fill-mode: forwards;
          -ms-animation-fill-mode: forwards;
        }

        @keyframes animate-btn-to-horizontal{
          from, 10%{
              right: 0;
          }
          20%,40%{
              transform: rotate(-30deg);
              -moz-transform: rotate(-30deg);
              -webkit-transform: rotate(-30deg);
              -ms-transform: rotate(-30deg);
          }
          60%{
              transform: rotate(30deg);
              -moz-transform: rotate(30deg);
              -webkit-transform: rotate(30deg);
              -ms-transform: rotate(30deg);
          }
          70%{
              transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
          }
          80%{
              transform: rotate(-20deg);
              -moz-transform: rotate(-20deg);
              -webkit-transform: rotate(-20deg);
              -ms-transform: rotate(-20deg);
          }
          60%, to{
              right: 147px;
          }
        }
        @keyframes animate-btn-from-horizontal{
          from, 10%{
              right: 147px;
          }
          20%,40%{
              transform: rotate(30deg);
              -moz-transform: rotate(30deg);
              -webkit-transform: rotate(30deg);
              -ms-transform: rotate(30deg);
          }
          60%{
              transform: rotate(-30deg);
              -moz-transform: rotate(-30deg);
              -webkit-transform: rotate(-30deg);
              -ms-transform: rotate(-30deg);
          }
          70%{
              transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
          }
          80%{
              transform: rotate(20deg);
              -moz-transform: rotate(20deg);
              -webkit-transform: rotate(20deg);
              -ms-transform: rotate(20deg);
          }
          60%, to{
              right: 0;
          }
        }
        @keyframes animate-into-desc-horizontal{
          from, 50%{
            opacity: 0;
          }
          70%, 100%{
            opacity: 1;
          }
          30%{
            transform: translateY(50px);
          }
        }
        @keyframes animate-btn-to-vertical{
          from, 10%{
              bottom: 20px;
          }
          20%,40%{
              transform: rotate(-30deg);
              -moz-transform: rotate(-30deg);
              -webkit-transform: rotate(-30deg);
              -ms-transform: rotate(-30deg);
          }
          60%{
              transform: rotate(30deg);
              -moz-transform: rotate(30deg);
              -webkit-transform: rotate(30deg);
              -ms-transform: rotate(30deg);
          }
          70%{
              transform: rotate(0deg);
              -moz-transform: rotate(0deg);
              -webkit-transform: rotate(0deg);
              -ms-transform: rotate(0deg);
          }
          80%{
              transform: rotate(-20deg);
              -moz-transform: rotate(-20deg);
              -webkit-transform: rotate(-20deg);
              -ms-transform: rotate(-20deg);
          }
          60%, to{
              bottom: 95px;
          }
        }
        @keyframes animate-btn-from-vertical{
          from, 10%{
                bottom: 95px;
            }
            20%,40%{
                transform: rotate(30deg);
                -moz-transform: rotate(30deg);
                -webkit-transform: rotate(30deg);
                -ms-transform: rotate(30deg);
            }
            60%{
                transform: rotate(-30deg);
                -moz-transform: rotate(-30deg);
                -webkit-transform: rotate(-30deg);
                -ms-transform: rotate(-30deg);
            }
            70%{
                transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
            }
            80%{
                transform: rotate(20deg);
                -moz-transform: rotate(20deg);
                -webkit-transform: rotate(20deg);
                -ms-transform: rotate(20deg);
            }
            60%, to{
                bottom: 20px;
            }
        }
        @keyframes animate-into-desc-vertical{
            from, 50%{
                opacity: 0;
            }
            70%, 100%{
                opacity: 1;
            }
            30%{
                transform: translateX(50px);
            }
        }
        #noti-info-client #wrapper-noti-body.horizontal {
          position: fixed;
          width: auto;
          bottom: 100px;
          background: ${secondary};
          -webkit-box-shadow: 0px 3px 6px 0px rgba(235,235,235,.3);
          -moz-box-shadow: 0px 3px 6px 0px rgba(235,235,235,.3);
          -ms-box-shadow: 0px 3px 6px 0px rgba(235,235,235,.3);
          box-shadow: 0px 3px 6px 0px rgba(235,235,235,.3);
          border-radius: 4px;
          display: none;
          border: 1px solid #DDDDDD;
        }
        #noti-info-client #wrapper-noti-body.vertical {
          position: fixed;
          width: auto;
          bottom: 55px;
          background: #fff;
          -webkit-box-shadow: 0px 3px 6px 0px rgba(235,235,235,.3);
          -moz-box-shadow: 0px 3px 6px 0px rgba(235,235,235,.3);
          -ms-box-shadow: 0px 3px 6px 0px rgba(235,235,235,.3);
          box-shadow: 0px 3px 6px 0px rgba(235,235,235,.3);
          border-radius: 4px;
          display: none;
          border: 1px solid #DDDDDD;
        }
        #noti-info-client #wrapper-noti-body #box {
          min-height: 150px;
          width: 300px;
          text-align: center;
          padding: 10px 20px 20px;
        }
        #noti-info-client #wrapper-noti-body #box #closeButton {
          position: absolute;
          top: 0;
          right: 5px;
          transform: rotate(0deg);
          color:#3d3d3d;
          opacity: 0.5;
          transition: all 0.15s;
          cursor: pointer;
          font-size: 20px;
        }
        #noti-info-client #wrapper-noti-body #box #box-content{
            letter-spacing: 1px;
            padding-top: 10px !important;
            min-height: 100px;
        }
        #noti-info-client #wrapper-noti-body #box #box-content p{
          font-size: 16px;
          margin: 0px;
          padding-top: 8px;
        }
        #noti-info-client #wrapper-noti-body #box #btn-ok {
          margin-top: 10px !important;
          position: relative;
        }
        #submit-button:hover {
          opacity: 0.3;
        }
        #noti-info-client #wrapper-noti-body #box #btn-ok button.btn {
          background-color: ${primary};
          padding: 10px 30px;
          display: inline-block;
          color: ${secondary};
          font-size: 14px;
          text-align: center;
          outline: 0;
          cursor: pointer;
          border-radius: 4px;
          text-transform: none;
        }
        .active-body-desc{
          display: block !important;
            /* keyframe */
          animation:  2s animate-into-desc-${animateTypeBtn} !important;
          -webkit-animation: 2s animate-into-desc-${animateTypeBtn} !important;
          -moz-animation: 2s animate-into-desc-${animateTypeBtn} !important;
          -ms-animation: 2s animate-into-desc-${animateTypeBtn} !important;
        }
        #noti-info-client #wrapper-noti-body div {
          list-style: none;
          margin: 0 !important;
        }
        #noti-info-client #wrapper-noti-body.horizontal::after, #noti-info-client #wrapper-noti-body.horizontal::before {
          top: 100%;
          left: 50%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-top-color: #DDDDDD;
        }
        #noti-info-client #wrapper-noti-body.horizontal::after {
          border-top-color: #fff;
          border-width: 10px;
          margin-left: -10px;
        }
        #noti-info-client #wrapper-noti-body.horizontal::before {
            border-width: 12px;
            margin-left: -12px;
        }
        #noti-info-client #wrapper-noti-body.vertical::after, #noti-info-client #wrapper-noti-body.vertical::before {
          top: 50%;
          left: 100%;
          border: solid transparent;
          content: "";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-left-color: #DDDDDD;
        }
        #noti-info-client #wrapper-noti-body.vertical::after {
          border-left-color: #fff;
          border-width: 10px;
          margin-top: 1px;
        }
        #noti-info-client #wrapper-noti-body.vertical::before {
          border-width: 11px;
          margin-top: 0px;
        }
      </style>
      <div id="noti-info-client" class="${animateTypeBtn} popup-container bell-alert">
        <div id="panel" class="active-btn-to">
          <span id="showButton">
            <svg class="icon icon-notification">
              <use xlink:href="#icon-notification">
                <symbol id="icon-notification" viewBox="0 0 20 20">
                  <path d="M4 8c0-0.001 0-0.002 0-0.003 0-2.608 1.664-4.827 3.988-5.654l0.042-0.013c-0.016-0.095-0.025-0.204-0.025-0.315 0-1.105 0.895-2 2-2s2 0.895 2 2c0 0.111-0.009 0.22-0.027 0.327l0.002-0.012c2.361 0.843 4.020 3.060 4.020 5.664 0 0.002 0 0.004 0 0.006v-0 6l3 2v1h-18v-1l3-2v-6zM12 18c0 1.105-0.895 2-2 2s-2-0.895-2-2v0h4z"></path>
                </symbol>
              </use>
            </svg>
          </span>
        </div>
        <div id="wrapper-noti-body" class="${animateTypeBtn} active-body-desc">
          <div id="box" >
            <div id="closeButton">&times;</div>
            <div id="box-content">
              <pre>${message}</pre>
            </div>
            <div id="btn-ok" class="clearfix">
              <button type="button" id="submit-button" class="btn model-close">${buttonText}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
}
