import { IContext } from '../pam4-tracker'
import { IAttentionItem, AttentionType } from './types'

export interface IPopup {
  close(): void
}

export default class Injection implements IPopup {
  constructor(private ctx: IContext, private attentionItem: IAttentionItem) {
    this._render()
  }

  public close = (): void => {
    console.log('')
  }

  private async _render(): Promise<void> {
    if (this.attentionItem.options.container_id) {
      const container = window.document.getElementById(this.attentionItem.options.container_id)
      if (container != null) {
        switch (this.attentionItem.options.type) {
          case AttentionType.REPLACE:
            container.innerHTML = this.attentionItem.html
            break
          case AttentionType.PREPEND:
            container.innerHTML = this.attentionItem.html + container.innerHTML
            break
          case AttentionType.APPEND:
            container.innerHTML += this.attentionItem.html
            break
          default:
            console.error(`Attention's type is not found`)
        }

        const script = window.document.createElement('script')
        const inlineScript = window.document.createTextNode(this.attentionItem.js)
        script.appendChild(inlineScript)
        container.appendChild(script)

        const style = window.document.createElement('style')
        let css = this.attentionItem.css
        if (this.attentionItem.custom_css) {
          css += this.attentionItem.custom_css.replace('\n', '')
        }
        style.innerHTML = css
        container.appendChild(style)
      } else {
        console.error(`Web-attention container's ID '${this.attentionItem.options.container_id}' is not found!`)
      }
    } else {
      console.error(`Web-attention container's id is invalid!`)
    }
  }
}
