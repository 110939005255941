import ShadowDOM from '../ShadowDOM'

export default class PusherNavigation extends ShadowDOM {
  constructor(public navigationText = 'Click Allow to subscribe') {
    super()
  }

  public render(): void {
    super.render()
    const html = `
    <div>
      <style>
      .webpush-navigation {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 999999;
      }

      .webpush-navigation svg {
        width: 100px;
        fill: #ffffff;
      }

      .webpush-navigation p {
        font-size: 1.4em;
        text-shadow: 2px 2px 5px #444444;
      }

      .wrapper {
        position: relative;
        text-align: left;
        top: 150px;
        left: 500px;
        color: #ffffff;
      }
      </style>
      <div class="webpush-navigation">
        <div class="wrapper">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 869.58 1381.15">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Draw_Layer" data-name="Draw Layer" transform="translate(20,2.5) rotate(10)">
                <path class="cls-1" d="M31.15,27.47A16.25,16.25,0,0,0,25.33,49.7c.84,1.1,1.69,2.19,2.54,3.29A16.27,16.27,0,0,0,35,57.17q14.3,5.41,28.42,11.26A16.37,16.37,0,0,0,67.11,70C147.9,104.44,223.56,150,293.19,203.5,378,269.37,453.45,346.61,519.28,431.36c78.27,101.72,142.5,213.79,192.57,331.91a1632.82,1632.82,0,0,1,111.71,409.28q4.5,34,7.56,68.22v0a.28.28,0,0,1,0,.09q4.2,47.73,5.48,95.64.36,14.19.47,28.38a16.25,16.25,0,0,0,32.5,0q-.12-14.16-.47-28.33h0L869,1333c0-.15,0-.3,0-.45q-1.34-45.74-5.34-91.33s0-.09,0-.14,0-.08,0-.13a1638.1,1638.1,0,0,0-117.4-479,1570.43,1570.43,0,0,0-111.4-219.83,1441.5,1441.5,0,0,0-147-199.62q-40.35-45.9-84.92-87.8c-29.7-28-60.89-54.25-93.19-79.16-61-47-126.81-87.78-196.55-120.42-9.56-4.47-19.22-8.74-28.92-12.9l-.1-.06-3-1.26-3.76-1.59-1.61-.67h0q-6-2.51-12-4.93a16.63,16.63,0,0,0-3.58-1.47q-7.4-2.94-14.84-5.77l-.62-.24-1-.39-4.32-.58a16.21,16.21,0,0,0-8.21,2.22Z"/>
                <path class="cls-1" d="M226.74,0,76.55,7.4,16.47,10.35a16.14,16.14,0,0,0-8.23,2.12,16.45,16.45,0,0,0-5.9,5.76C-.53,23-.95,29.82,2.15,34.63q45.71,70.92,91.44,141.82l25.55,39.62a16.92,16.92,0,0,0,9.62,7.59,16.27,16.27,0,0,0,20.13-11.11c1.15-4,.81-9-1.49-12.54L53.34,54.14l-8.2-12.72L176.74,35l49.34-2.43h.27c4.41-.21,8.33-1.47,11.55-4.62a16.25,16.25,0,0,0,.28-23A16.5,16.5,0,0,0,228.34.05a12.22,12.22,0,0,0-1.6,0Z"/>
              </g>
            </g>
          </svg>
          <p>${this.navigationText}</p>
        </div>
      </div>
    </div>
    `
    const section = document.createElement('section')
    section.innerHTML = html
    this.shadowRoot.appendChild(section)
  }
}
