import { IContext } from '../pam4-tracker'
import { IAttentionItem, ISize, SizeType, NewSizeType } from './types'
import { toggleClass } from '../util'
import { POPUP_OPEN_CLASS, SIZE } from './consts'
import { popupTemplate } from './popupTemplate'

export interface IPopup {
  close(): void
}

export default class Popup implements IPopup {
  constructor(
    private ctx: IContext,
    private shadow: ShadowRoot,
    private shadowHost: HTMLDivElement,
    private attentionItem: IAttentionItem,
    private onClose: () => void
  ) {
    this._render()
  }

  public close = (): void => {
    if (this.shadowHost) {
      toggleClass(window.document.body, POPUP_OPEN_CLASS)
      this.shadowHost.remove()
    }
  }

  private async _render(): Promise<void> {
    const globalStyle = window.document.createElement('style')
    globalStyle.innerHTML = `.${POPUP_OPEN_CLASS} {overflow: hidden !important;}`
    document.body.insertBefore(globalStyle, document.body.firstChild)

    const section = window.document.createElement('section')
    this._createElement(section, this.attentionItem)
    toggleClass(window.document.body, POPUP_OPEN_CLASS)

    const isAllowBackdropClick = this.attentionItem.options.allow_backdrop_click || false
    if (isAllowBackdropClick) {
      const popupBackdrop = this.shadow.querySelectorAll<HTMLDivElement>('.attention-popup-backdrop')
      popupBackdrop[0].onclick = this.onClose
    }

    const popupCloseBtn = this.shadow.querySelectorAll<HTMLDivElement>('.attention-popup-close')
    popupCloseBtn[0].onclick = this.onClose
  }

  private _createElement(section: HTMLElement, attentionItem: IAttentionItem): void {
    section.innerHTML = popupTemplate({
      html: attentionItem.html,
      width: Popup._getPopupSize(attentionItem.options.size),
      backdropOpacity: attentionItem.options.backdrop_opacity !== null ? attentionItem.options.backdrop_opacity : 0.6,
      isBorderless: attentionItem.options.is_borderless || false,
    })

    this.shadow.appendChild(section)
  }

  private static _getPopupSize(size: ISize): string {
    switch (size.type) {
      case NewSizeType.SM:
      case SizeType.SM:
        return SIZE.SM
      case NewSizeType.MD:
      case SizeType.MD:
        return SIZE.MD
      case NewSizeType.LG:
      case SizeType.LG:
        return SIZE.LG
      case NewSizeType.FULL:
      case SizeType.FULL:
        return SIZE.FULL
      case NewSizeType.CUSTOM:
      case SizeType.CUSTOM:
        return size.width ? size.width + 'px' : SIZE.DEFAULT
      default:
        return SIZE.DEFAULT
    }
  }
}
