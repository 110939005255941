import { IContext } from '../pam4-tracker'
import { DBType, getCurrentDBType, parseBool, readCookie } from '../util'
import NotificationPopup from './NotificationPopup'
import PermissionManagement from './PermissionManagement'
import Service from '../Service'
import { IConsentMessage, IPermissionBody, ICustomerConsentDetail, ITrackerResponse } from '../Service/types'
import { ISetLoginStatePayload } from './types'
import { getFormatData, IFormatData } from '../util'
export default class ConsentManager {
  private readonly NUMBER_OF_MINUTES_TO_CHECK_POPUP_PERMISSION = 1
  private NUMBER_OF_HOUR_TO_GET_CONSENT_API = 1
  private readonly HAS_LOGGEDIN_KEY = 'pam_has_loggedin'
  private readonly LOGIN_ID_KEY = 'pam_login_id'

  public consentMessage!: IConsentMessage
  public customerConsentDetail: ICustomerConsentDetail | null
  private notificationPopups: NotificationPopup[]
  private _hasLoggedIn: boolean
  public loginIdKey: string
  public loginId: string | null
  private _isFetching: boolean
  private _isTrackingConsentRefreshing: boolean

  constructor(private ctx: IContext, private service: Service) {
    this.customerConsentDetail = null
    this.notificationPopups = []
    this._hasLoggedIn = parseBool(window.localStorage.getItem(this.HAS_LOGGEDIN_KEY) ?? '')
    this.loginIdKey = this.ctx.config.loginKey
    this.loginId = window.localStorage.getItem(this.LOGIN_ID_KEY)
    this._isFetching = false
    this._isTrackingConsentRefreshing = false

    if (ctx.config.consentRenewalPeriodDays !== undefined) {
      this.NUMBER_OF_HOUR_TO_GET_CONSENT_API = ctx.config.consentRenewalPeriodDays * 24
    } else {
      this.NUMBER_OF_HOUR_TO_GET_CONSENT_API = 30 * 24
    }
  }

  public get isFetching(): boolean {
    return this._isFetching
  }

  public get isTrackingConsentRefreshing(): boolean {
    return this._isTrackingConsentRefreshing
  }

  public getContactId(): string | null {
    return window.localStorage.getItem('contact_id')
  }

  public async start(): Promise<void> {
    this._isFetching = true
    if (!this.ctx.config.trackingConsentMessageId) {
      console.error('PAM tracking consent message id must be provided')
      this._isFetching = false
      return
    }

    try {
      this.consentMessage = await this.service.fetchConsentMessage()
    } catch (e) {
      console.error(e)
      this._isFetching = false
      return
    }

    const contactId = this.getContactId()
    if (contactId) {
      try {
        await this.refreshTrackingConsentStatus()
      } catch (e) {
        this._isFetching = false
      }
    }

    if (!this.ctx.config.disableInstantPopupUI) {
      await this.getShowConsentPopup()
    }
    // else {
    //   const contactId = getContactId()

    //   if (contactId) {
    //     try {
    //       await this.refreshTrackingConsentStatus()
    //     } catch (e) {
    //       console.error(e)
    //       this._isFetching = false
    //       return
    //     }
    //   }
    // }

    this._isFetching = false
  }

  public async getShowConsentPopup(): Promise<void> {
    await this.setIsShowConsentPopup(false)

    setInterval(() => this.setIsShowConsentPopup(), this.NUMBER_OF_MINUTES_TO_CHECK_POPUP_PERMISSION * 60 * 1000)
  }

  public async setIsShowConsentPopup(ignoreTimestamp = false): Promise<void> {
    let isShowPopup = this.isLastPopupMoreThanHours(this.NUMBER_OF_HOUR_TO_GET_CONSENT_API)
    if (ignoreTimestamp) {
      isShowPopup = true
    }

    if (isShowPopup) {
      const contactId = this.getContactId()

      if (contactId) {
        try {
          await this.refreshTrackingConsentStatus()
        } catch (e) {
          console.error(e)
          return
        }
      }

      const popupStack: NotificationPopup[] = []
      if (!contactId || !this.customerConsentDetail || this.customerConsentDetail.need_consent_review) {
        const popup = new NotificationPopup(this.consentMessage, this.submitConsentAndRefreshStatus, true)
        this.notificationPopups.push(popup)
        popupStack.push(popup)
      }

      if (contactId && this.ctx.config.contactingConsentMessageIds) {
        for (const consentMsgId of this.ctx.config.contactingConsentMessageIds) {
          try {
            const consentDetail = await this.service.getCustomerConsentDetail(contactId, consentMsgId)
            if (consentDetail.need_consent_review) {
              const popup = await this.createPopup(consentMsgId, true)
              popupStack.push(popup)
            }
          } catch (e) {}
        }
      }

      while (popupStack.length) {
        popupStack.pop()?.render()
      }
    }
  }

  // return true if latestGetCustomerConsent is null (never notify)
  private isLastPopupMoreThanHours(hours: number): boolean {
    const latestGetCustomerConsent = window.localStorage.getItem('latest_get_customer_consent')

    if (latestGetCustomerConsent) {
      return (new Date().getTime() - new Date(latestGetCustomerConsent).getTime()) / (3600 * 1000) > hours
    }

    return true
  }

  public get isTrackingAllowed(): boolean {
    return !!this.customerConsentDetail?.tracking_permission?.preferences_cookies && !!this.getContactId()
  }

  public submitConsentToPublicDatabase = async (
    permissionBody: IPermissionBody,
    formFields: Record<string, unknown> = {}
  ): Promise<ITrackerResponse> => {
    let database = this.ctx.config.publicDBAlias
    if (permissionBody.database) {
      database = permissionBody.database
    }

    const consentPerms: any = {
      _consent_message_id: permissionBody.consent_message_id,
      _version: permissionBody.version,
      _database: database,

      // override all fields
      ...formFields,
    }

    if (this.hasLoggedIn && this.loginIdKey && this.loginId) {
      consentPerms[this.loginIdKey] = this.loginId
    }

    if (permissionBody.permission) {
      for (const [key, val] of Object.entries(permissionBody.permission)) {
        consentPerms[`_allow_${key}`] = val
      }
    }

    return this.service.sendEvent('allow_consent', '', { ...consentPerms }, DBType.PUBLIC)
  }

  public submitMultipleConsent = async (consents: Record<string, PermissionManagement>): Promise<any> => {
    const events: IFormatData[] = []

    const database = getCurrentDBType() === DBType.LOGIN ? this.ctx.config.loginDBAlias : this.ctx.config.publicDBAlias

    for (const i in consents) {
      const c = consents[i]

      const formField: Record<string, any> = {}
      formField['_consent_message_id'] = c.consentAcceptation.consent_message_id
      formField['_version'] = c.consentAcceptation.version
      formField['_database'] = database

      for (const i in c.permission) {
        const p = c.permission[i]
        formField[`_allow_${p.key}`] = p.allow
      }

      const event = getFormatData('allow_consent', formField)
      events.push(event)
    }

    const payload = {
      _use_first_contact_id_for_all_events: true,
      events: events,
    }

    const result = await this.service.sendBulkEvent(payload)

    const resultMap: Record<string, ITrackerResponse> = {}

    let cnt = 0
    for (const i in consents) {
      const c = consents[i]
      resultMap[c.consentMessageID] = result[cnt]
      cnt++
    }

    return resultMap
  }

  // for allow_to_contact consent type
  public submitConsent = async (
    permissionBody: IPermissionBody,
    formFields: Record<string, unknown> = {}
  ): Promise<ITrackerResponse> => {
    let database = getCurrentDBType() === DBType.LOGIN ? this.ctx.config.loginDBAlias : this.ctx.config.publicDBAlias
    if (permissionBody.database) {
      database = permissionBody.database
    }

    const consentPerms: any = {
      _consent_message_id: permissionBody.consent_message_id,
      _version: permissionBody.version,
      _database: database,

      // override all fields
      ...formFields,
    }

    if (this.hasLoggedIn && this.loginIdKey && this.loginId) {
      consentPerms[this.loginIdKey] = this.loginId
    }

    if (permissionBody.permission) {
      for (const [key, val] of Object.entries(permissionBody.permission)) {
        consentPerms[`_allow_${key}`] = val
      }
    }

    return this.service.sendEvent('allow_consent', '', { ...consentPerms })
  }

  private submitConsentAndRefreshStatus = async (
    permissionBody: IPermissionBody,
    formFields: Record<string, unknown> = {}
  ): Promise<ITrackerResponse> => {
    try {
      const res = await this.submitConsent(permissionBody, formFields)
      await this.refreshTrackingConsentStatus()
      return res
    } catch (e) {
      throw e
    }
  }

  public async refreshTrackingConsentStatus(): Promise<void> {
    this._isTrackingConsentRefreshing = true
    const contactId = this.getContactId()
    if (!contactId) {
      this._isTrackingConsentRefreshing = false
      throw new Error('contact_id is not defined')
    }

    try {
      this.customerConsentDetail = await this.service.getCustomerConsentDetail(
        contactId,
        this.ctx.config.trackingConsentMessageId
      )
    } catch (e) {
      this.customerConsentDetail = null
      if ((e as any).code === 'NOT_FOUND') {
        this._isTrackingConsentRefreshing = false
        return
      }
      this._isTrackingConsentRefreshing = false
      throw e
    }
    this._isTrackingConsentRefreshing = false
  }

  public displayConsentMessageModal = async (consentMessageID: string): Promise<void> => {
    const consentMessage = await this.service.fetchConsentMessage(consentMessageID)
    const popup = new NotificationPopup(consentMessage, this.submitConsent, true)
    this.notificationPopups.push(popup)
    popup.renderOnlyPopup()
  }

  public createPopup = async (
    consentMessageID: string,
    immediatelySubmit: boolean,
    callback?: (prev: any, state: any) => void,
    disableToggleOptions = false
  ): Promise<NotificationPopup> => {
    const consentMessage = await this.service.fetchConsentMessage(consentMessageID)
    const popup = new NotificationPopup(
      consentMessage,
      this.submitConsent,
      immediatelySubmit,
      callback,
      false,
      disableToggleOptions
    )
    this.notificationPopups.push(popup)
    return popup
  }

  public loadConsentDetails = async (consentMessageIDs: string[]): Promise<Record<string, PermissionManagement>> => {
    const consentMessages: Record<string, PermissionManagement> = {}
    for (let i = 0; i < consentMessageIDs.length; i++) {
      const consentMessage = await this.service.fetchConsentMessage(consentMessageIDs[i])
      consentMessages[consentMessageIDs[i]] = new PermissionManagement(consentMessage, this.submitConsent)
    }
    return consentMessages
  }

  public clear(): void {
    for (const popup of this.notificationPopups) {
      popup.close()
    }
    this.notificationPopups = []
  }

  public setLoginState(payload: ISetLoginStatePayload): void {
    if (payload.hasLoggedIn) {
      this.loginId = window.localStorage.getItem(this.LOGIN_ID_KEY)

      if (!this.loginIdKey) {
        console.error('login_id key must be provided')
        return
      }

      const loginId = payload.loginId || this.loginId

      if (!loginId) {
        console.log('login_id must be provided')
        return
      }

      this._hasLoggedIn = true
      window.localStorage.setItem(this.HAS_LOGGEDIN_KEY, 'true')
      window.localStorage.setItem(this.LOGIN_ID_KEY, loginId)
      window.localStorage.setItem('db_type', DBType.LOGIN)
    } else {
      this._hasLoggedIn = false
      this.loginId = null
      window.localStorage.setItem(this.HAS_LOGGEDIN_KEY, 'false')
      window.localStorage.setItem('db_type', DBType.PUBLIC)
    }
  }

  public async userLogin(id: string): Promise<void> {
    this.clear()

    const payload = {
      _database: this.ctx.config.loginDBAlias,
      [this.loginIdKey]: id,
    }

    try {
      await this.service.sendEvent('login', '', payload)

      this._hasLoggedIn = true
      this.loginId = id
      window.localStorage.setItem(this.HAS_LOGGEDIN_KEY, 'true')
      window.localStorage.setItem(this.LOGIN_ID_KEY, id)
      window.localStorage.setItem('db_type', DBType.LOGIN)

      await this.setIsShowConsentPopup(true)
    } catch (e) {
      throw e
    }
  }

  public async userLogout(): Promise<void> {
    this.clear()

    if (!this.loginIdKey) {
      throw new Error('login_id key must be provided')
    }

    if (!this.loginId) {
      throw new Error('login_id must be provided')
    }

    const payload = {
      _database: this.ctx.config.loginDBAlias,
      [this.loginIdKey]: this.loginId,
    }

    try {
      await this.service.sendEvent('logout', '', payload)

      this._hasLoggedIn = false
      this.loginId = null
      window.localStorage.setItem(this.HAS_LOGGEDIN_KEY, 'false')
      const publicContactId = readCookie(`${this.ctx.config.publicDBAlias}_contact_id`) ?? ''
      window.localStorage.setItem(this.LOGIN_ID_KEY, publicContactId)
      window.localStorage.setItem('db_type', DBType.PUBLIC)
      await this.setIsShowConsentPopup(true)
    } catch (e) {
      throw e
    }
  }

  public get hasLoggedIn(): boolean {
    return this._hasLoggedIn
  }

  public async getCustomerConsentDetail(
    consentMessageId: string
  ): Promise<ICustomerConsentDetail | Record<string, unknown>> {
    this._isFetching = true
    let consentDetail = {}
    try {
      const contactId = this.getContactId()
      if (contactId) {
        consentDetail = await this.service.getCustomerConsentDetail(contactId!, consentMessageId)
        return consentDetail
      }
    } catch (e) {
    } finally {
      this._isFetching = false
    }
    return consentDetail
  }
}
