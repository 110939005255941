import { IFacebookEvent, IFacebookEventResponse } from './types'
import { getRequester } from '../util'
import { IContext } from '../pam4-tracker'

export default class FacebookAds {
  public hasAlreadyInitialzed = false

  constructor(private ctx: IContext) {}

  public async init(): Promise<void> {
    if (this.hasAlreadyInitialzed) {
      throw new Error('facebook ads instance has already initialized')
    }
    try {
      const { events } = await this.fetchEvents()
      this.installScript()
      this.initAndTrackFacebookEvent(events)
      this.hasAlreadyInitialzed = true
    } catch (e) {
      console.error(e)
    }
  }

  private fetchEvents(): Promise<IFacebookEventResponse> {
    return getRequester<IFacebookEventResponse>(`${this.ctx.config.baseApi}/audiences/events`)
  }

  private installScript(): void {
    const heads = window.document.getElementsByTagName('head')
    if (!heads.length) {
      throw new Error('no head element')
    }

    const script = window.document.createElement('script')
    script.innerText = `!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');`

    heads[0].appendChild(script)
  }

  private initAndTrackFacebookEvent(events: IFacebookEvent[]): void {
    const uniquePixelIds = Array.from(new Set(events.map((e) => e.pixel_id)))
    uniquePixelIds.forEach((id) => {
      ;(window as any).fbq('init', id)
    })
    events.forEach((e) => {
      ;(window as any).fbq('trackSingleCustom', e.pixel_id, 'Other', {
        name: 'PamSegment',
        value: e.segment,
      })
    })
  }
}
