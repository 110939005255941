import ConsentManager from '../ConsentManager'

declare function gtag(op: string, ...args: string[]): void

export default class GoogleAnalytics {
  public gaIds: string[]

  constructor(private consentManager: ConsentManager) {
    this.gaIds = this.consentManager.consentMessage?.setting?.analytics_cookies?.tracking_collection['google_tag_id'] ?? []
  }

  public installScript(): void {
    if (!this.gaIds.length) {
      return
    }

    const heads = window.document.getElementsByTagName('head')
    if (!heads.length) {
      throw new Error('no head element')
    }

    const script1 = window.document.createElement('script')
    script1.async = true
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${this.gaIds[0]}`

    const script2 = window.document.createElement('script')
    script2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); ${this.gaIds.map(
      (id) => `gtag('config', '${id}'); `
    )}`

    heads[0].appendChild(script1)
    heads[0].appendChild(script2)
  }

  public track(): void {
    if (!this.gaIds.length) {
      return
    }

    if (this.consentManager.customerConsentDetail?.tracking_permission?.analytics_cookies) {
      for (const id of this.gaIds) {
        gtag('config', id)
      }
    }
  }
}
