import ConsentManager from '../ConsentManager'

declare function fbq(op: string, ...arg: string[]): void

export default class FacebookPixel {
  public pixelIds: string[]
  constructor(private consentManager: ConsentManager) {
    this.pixelIds =
      this.consentManager.consentMessage?.setting?.social_media_cookies?.tracking_collection['facebook_pixel_id'] ?? []
  }

  public installScript(): void {
    if (!this.pixelIds) {
      return
    }

    const heads = window.document.getElementsByTagName('head')
    if (!heads.length) {
      throw new Error('no head element')
    }

    const script = window.document.createElement('script')
    script.innerText = `!function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js');`

    heads[0].appendChild(script)

    for (const id of this.pixelIds) {
      fbq('init', id)
    }

    this.track()
  }

  public track(): void {
    if (!this.pixelIds) {
      return
    }

    if (this.consentManager.customerConsentDetail?.tracking_permission?.social_media_cookies) {
      for (const id of this.pixelIds) {
        fbq('trackSingle', id, 'PageView')
      }
    }
  }
}
